import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction as MuiBottomNavigationAction,
  Typography,
  styled
} from '@mui/material'
import useVibrate from '../../lib/useVibrate'
import { useNavigate, useLocation } from 'react-router-dom';
import { isTMA, retrieveLaunchParams, requestViewport } from '@tma.js/sdk';
import MineIcon from '../dumps/icons/MineIcon';
import TapIcon from '../dumps/icons/TapIcon';
import FriendsIcon from '../dumps/icons/FriendsIcon';
import SettingsIcon from '../dumps/icons/SettingsIcon';

const Root = styled('div')<{ vheight?: number }>(({ theme, vheight }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  //height: short ? '94dvh' : '100dvh',
  // height: '100%',
  height: vheight ? `${vheight}px` : '100%',
  overflow: 'hidden',
}))

/*
const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 'calc(100% + 1px)',
  maxHeight: 'calc(100% + 1px)',
  overflow: 'hidden',
}))
*/

const Main = styled('main')<{ vheight?: number, fullSize?: boolean }>(({ theme, vheight, fullSize }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // flexGrow: 1,
  width: fullSize ? '100%' : '90%',
  height: vheight ? `${vheight}px` : (fullSize ? '100%' : 'calc(100% - 56px - 12px)'),
  // The current height of the visible area of the Mini App. Also available in CSS as the variable var(--tg-viewport-height).
  // overflow: 'hidden',
}))

const BottonContainer = styled('div')<{ bottom: number }>(({ theme, bottom }) => ({
  width: '100%',
  position: 'absolute',
  bottom: bottom + theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
}))

const BottomNavigation = styled(MuiBottomNavigation)(({ theme }) => ({
  width: '90%',
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.background.paper,
}))

const BottomNavigationAction = styled(MuiBottomNavigationAction)(({ theme }) => ({

}))

const BottomNavigationLabel = styled(Typography)(({ theme }) => ({
  fontSize: 10,
}))

const unselectedColor = '#5F6368'
const selectedColor = '#FFB800'

type Props = {
  title?: string,
  children?: React.ReactNode,
  hideBottomNav?: boolean,
  fullSize?: boolean,
}

const MobilePage = ({ children, hideBottomNav, fullSize }: Props) => {
  const { vibrate } = useVibrate()
  const navigate = useNavigate()
  const handleMenuChange = useCallback((event: React.ChangeEvent<{}>, newValue: string) => {
    navigate(newValue)
    vibrate(20)
  }, [navigate])

  const [bottomOffset, setBottomOffset] = useState<number>(0)
  const [viewportHeight, setViewportHeight] = useState<number | undefined>()
  const rootRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    isTMA().then(async (isTMA) => {
      if (isTMA) {
        const lp = retrieveLaunchParams();

        // Some versions of Telegram don't need the classes above.
        if (['macos', 'tdesktop', 'weba', 'web', 'webk'].includes(lp.platform)) {
          return;
        }
        
        const v = await requestViewport();
        if(window.innerHeight !== v.height) {
          //setViewportHeight(v.height);
          //setBottomOffset(window.innerHeight - v.height);
        }
      }
    }).catch((error) => {
      console.error('MyApp isTMA error', error);
    });
  }, [rootRef]);


  const location = useLocation();
  const current = location.pathname;

  return (
    <Root ref={rootRef} vheight={viewportHeight}>
      <Main vheight={viewportHeight} fullSize={fullSize}>
        {children}
      </Main>
      <BottonContainer
        bottom={bottomOffset}
        sx={{
          display: hideBottomNav ? 'none' : 'flex'
        }}
      >
        <BottomNavigation showLabels onChange={handleMenuChange}>
          <BottomNavigationAction
            label={<BottomNavigationLabel>Tap</BottomNavigationLabel>}
            icon={<TapIcon htmlColor={current === '/game' ? selectedColor : unselectedColor} />}
            value='/game'
          />
          <BottomNavigationAction
            label={<BottomNavigationLabel>Mine</BottomNavigationLabel>}
            icon={<MineIcon htmlColor={current === '/store' ? selectedColor : unselectedColor} />}
            value='/store'
          />
          <BottomNavigationAction
            label={<BottomNavigationLabel>Friends</BottomNavigationLabel>}
            icon={<FriendsIcon htmlColor={current === '/friends' ? selectedColor : unselectedColor} />}
            value='/friends'
          />
          <BottomNavigationAction
            label={<BottomNavigationLabel>Settings</BottomNavigationLabel>}
            icon={<SettingsIcon htmlColor={current === '/additional' ? selectedColor : unselectedColor} />}
            value='/additional'
          />
        </BottomNavigation>
      </BottonContainer>
    </Root>
  )
}

export default MobilePage
