import React, { useCallback } from 'react';
import WebApp from '@twa-dev/sdk';
import { useAccount } from '../providers/AccountProvider';
import GoldButton, { GoldButtonProps } from '../dumps/GoldButton';
import { styled } from '@mui/material';

export type InviteFriendButtonProps = Omit<GoldButtonProps, 'onClick'>;
const botUserName = 'KickAndDig_bot'

const Img = styled('img')({
  width: '16px',
  height: '16px',
  marginLeft: '4px',
  marginRight: '4px',
  transform: 'translateY(-1px)',
});

const InviteFriendButton: React.FC<InviteFriendButtonProps> = ({ ...props }) => {
  const { user } = useAccount();
  const handleInviteFriend = useCallback(() => {
    WebApp.openTelegramLink(
      `https://t.me/share/url?url=http://t.me/${botUserName}?start=fren=${user?.id}`
    );
  }, [user?.id]);

  return (
    <GoldButton size='large' {...props} onClick={handleInviteFriend} disabled={!user} >
      Invite Friend + <Img src='/coin.webp' alt='coin' />  5000 
    </GoldButton>
  );
};

export default InviteFriendButton;