import { useCallback } from 'react';

const useVibrate = () => {
  const isNavigatorAvailable = typeof navigator !== 'undefined';
  const isSupported = isNavigatorAvailable ? 'vibrate' in navigator : false;

  const vibrate = useCallback((pattern: number | number[]) => {
    if (isSupported) {
      navigator.vibrate(pattern);
    } else {
      // console.warn('Vibration is not supported');
    }
  }, [isSupported]);

  return { isSupported, vibrate };
};

export default useVibrate;