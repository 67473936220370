import React, { useEffect, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography, styled } from '@mui/material';
import { Upgrade } from '../../types/upgrade';
import * as api from '../../api/upgrades';
import { bounceAnimationCss } from '../../lib/animations';

const InfoBox = styled(Box)<{ duration?: string }>(({ theme, duration = '0.15s' }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  color: '#fff',
  minHeight: '109px',
  ...bounceAnimationCss(duration),
}));


const InfoHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  flexGrow: 1,
  paddingTop: '10px',
  paddingLeft: '10px',
  paddingRight: '10px',
});

const InfoContent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

const InfoFooter = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  paddingTop: '10px',
  paddingBottom: '10px',
  paddingLeft: '20px',
  paddingRight: '20px',
  backgroundColor: '#2C2F35',
  // bottom only borderRadius
  borderRadius: '0 0 10px 10px',
});

const ImageContainer = styled(Box)({
  width: '50px',
  height: '50px',
  borderRadius: '8px',
  overflow: 'hidden',
  marginRight: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#2d2d2d',
});

const Label = styled('div')<{ fontSize?: string, fontWeight?: number, color?: string }>(({
  fontSize,
  fontWeight,
  color,
}) => ({
  fontSize: fontSize || '10px',
  fontWeight: fontWeight || 600,
  color: color || '#fff',
}));

type InfoBoxData = {
  title: string;
  subtitle: string;
  profit: string;
  level: string;
  value: string;
  duration?: string;
};

type InfoBoxItemProps = InfoBoxData

const InfoBoxItem: React.FC<InfoBoxItemProps> = ({ title, subtitle, profit, level, value, duration }) => (
  <InfoBox duration={duration}>
    <InfoHeader>
      <ImageContainer>
        <img src="/logo128.webp" alt="icon" style={{ width: '100%' }} />
      </ImageContainer>
      <Box>
        <Label>
          {title}
        </Label>
        <Label>
          {subtitle} <span role="img" aria-label="coin">💰</span> {profit}
        </Label>
      </Box>
    </InfoHeader>
    <InfoFooter>
      <Label>
        {level}
      </Label>
      <Label fontSize='11px'>
        <span role="img" aria-label="coin">💰</span> {value}
      </Label>
    </InfoFooter>
  </InfoBox>
);

const UpgradeGrid = () => {
  const [upgrades, setUpgrades] = React.useState<Upgrade[]>([]);
  useEffect(() => {
    api.fetchUpgrades().then((items) => {
      setUpgrades(items);
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  const items = useMemo(() => {
    return upgrades.map((item) => ({
      id: item.id,
      title: item.name,
      subtitle: 'Profit /sec',
      profit: item.earn.toString(),
      level: `lvl ${item.level}`,
      value: item.cost.toString(),
    }));
  }, [upgrades]);

  return (
    <Grid container spacing={1}>
      {items.map((item, index) => (
        <Grid item xs={6} sm={6} md={6} key={item.id}>
          <InfoBoxItem {...item} duration={`${index * 0.05 + 0.1}s`} />
        </Grid>
      ))}
    </Grid>
  );
};

export default UpgradeGrid;
