import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { useAccount } from '../providers/AccountProvider'
import { useBalloon } from '../providers/AccountBallonProvider';
import { useAccountState } from '../providers/AccountStateProvider';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


type AccountResetButtonProps = Omit<ButtonProps, 'onClick'>;

const AccountResetButton: React.FC<AccountResetButtonProps> = ({ children,  ...props }: AccountResetButtonProps) => {
  const { resetAccount } = useAccount();
  const { resetBalloon } = useBalloon();
  const { reset} = useAccountState();

  const [open, setOpen] = React.useState(false);
  const handleResetAccount = () => {
    resetAccount();
    resetBalloon();
    reset();
    setOpen(false);
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} {...props}>
        {children}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Reset Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reset your account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleResetAccount} color="error">Reset</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(AccountResetButton);