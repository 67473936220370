import { alpha, styled } from "@mui/material";
import ShowEffect from "./ShowEffect";

const BlurPaper = styled(ShowEffect)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  width: '100%',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2.5),
  // add transparent background theme.palette.background.paper,
  background: alpha(theme.palette.background.paper, 0.5),
  backdropFilter: 'blur(2px)',
}));

export default BlurPaper;