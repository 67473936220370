import { vec3 } from "gl-matrix";
import { PrimitiveData } from "./Mesh";
import { Primitive } from "./Primitive";


function createPrimitiveData(geometry: HemisphereGeometry, bone: number): PrimitiveData {
  const { radius, widthSegments, heightSegments } = geometry;
  const vertices: vec3[] = [];
  const normals: vec3[] = [];
  const indices: number[] = [];
  const uvs: number[] = [];
  const skinWeights: number[] = [];
  const skinIndices: number[] = [];

  for (let y = 0; y <= heightSegments; y++) {
    const theta = y * Math.PI / heightSegments;
    const sinTheta = Math.sin(theta);
    const cosTheta = Math.cos(theta);

    for (let x = 0; x <= widthSegments; x++) {
      const phi = x * 2 * Math.PI / widthSegments;
      const sinPhi = Math.sin(phi);
      const cosPhi = Math.cos(phi);

      const vx = cosPhi * sinTheta;
      const vy = cosTheta;
      const vz = sinPhi * sinTheta;
      const u = x / widthSegments;
      const v = y / heightSegments;

      vertices.push([radius * vx, radius * vy, radius * vz]);
      normals.push([vx, vy, vz]);
      uvs.push(u, v);

      // attach tu up bone
      skinWeights.push(1, 0, 0, 0);
      skinIndices.push(bone, 0, 0, 0);
    }
  }

  for (let y = 0; y < heightSegments; y++) {
    for (let x = 0; x < widthSegments; x++) {
      const a = y * (widthSegments + 1) + x;
      const b = a + widthSegments + 1;

      indices.push(a + 1, b, a);
      indices.push(a + 1, b + 1, b);
    }
  }

  return {
    vertices,
    normals: normals.map(n => vec3.normalize(n, n)),
    indices,
    uvs,
    skinWeights,
    skinIndices
  };
}

export type HemisphereGeometry = {
  radius: number;
  widthSegments: number;
  heightSegments: number;
};

export class HemispherePrimitive extends Primitive {
  constructor(
    public geometry: HemisphereGeometry,
    public bone: number
  ) {
    super(createPrimitiveData(geometry, bone));
  }
}