import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { levels, scoreUp, scoreBonus, xp2Level } from '../../models/levels';
import * as api from '../../api/state';
import { useAccount } from './AccountProvider';

type AccountState = {
  score: number;
  xp: number;
};

type AccountStateContextType = AccountState & {
  loaded: boolean;
  level: number;
  addScore: (score: number) => void;
  reset: () => void;
}

const AccountStateContext = createContext<AccountStateContextType>({
  loaded: false,
  score: 0,
  xp: 0,
  level: 1,
  addScore: () => { throw new Error('AccountContext is not defined'); },
  reset: () => { throw new Error('AccountContext is not defined'); },
});

export type AccountStateProviderProps = {
  children: React.ReactNode;
};

export const AccountStateProvider: React.FC<AccountStateProviderProps> = ({ children }) => {
  const { user } = useAccount();

  const [loaded, setLoaded] = useState<boolean>(false);

  const [initState, setInitState] = useState<AccountState>({ xp: 0, score: 0 });
  const [score, setScore] = useState<number>(0);
  const [xp, setXp] = useState<number>(20000);

  const sumScore = initState.score + score;
  const sumXp = initState.xp + xp;
  const level = useMemo(() => xp2Level(sumXp), [sumXp]);

  const scoreRef = useRef(score);
  const xpRef = useRef(xp);
  const dirtyRef = useRef(false);

  useEffect(() => {
    // Current score and xp
    const savedScore = localStorage.getItem('score');
    const savedXp = localStorage.getItem('xp');

    if (user && (savedScore || savedXp)) {
      const initialScore = savedScore ? parseInt(savedScore) : 0;
      const initialXp = savedXp ? parseInt(savedXp) : 0;
      api.push({
        score: initialScore,
        xp: initialXp,
        level: xp2Level(initialXp),
      })
      .then((state) => {
        setInitState(state);
        localStorage.removeItem('score');
        localStorage.removeItem('xp');
        setLoaded(true);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoaded(true));
    } else {
      api.get()
        .then((state) => setInitState(state))
        .catch((e) => console.error(e))
        .finally(() => setLoaded(true));
    }
  }, [user]);

  const handleAddScore = useCallback((increment: number) => {
    setScore(oldScore => {
      const newScore = oldScore + increment;
      scoreRef.current = newScore;
      dirtyRef.current = true;
      return newScore;
    });
    setXp(oldXp => {
      const newXp = oldXp + increment;
      xpRef.current = newXp;
      dirtyRef.current = true;
      return newXp;
    });
  }, []);

  const handleResetAccount = useCallback(() => {
    setScore(0);
    setXp(0);
    scoreRef.current = 0;
    xpRef.current = 0;
    dirtyRef.current = true;
  }, []);

  const syncState = useCallback(async () => {
    try {
      const newState = await api.push({
        score: scoreRef.current,
        xp: xpRef.current,
        level,
      })
  
      setInitState(newState);
      setScore(0);
      setXp(0);
      scoreRef.current = 0;
      xpRef.current = 0;
    } catch (error) {
      console.error(error);
    }
  }, [level]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (dirtyRef.current) {
        dirtyRef.current = false;
        syncState()
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [syncState]);

  return (
    <AccountStateContext.Provider value={{
      loaded,
      score: sumScore,
      xp: sumXp,
      level,
      addScore: handleAddScore,
      reset: handleResetAccount,
    }}>
      {children}
    </AccountStateContext.Provider>
  );
};

export const useAccountState = () => {
  return React.useContext(AccountStateContext);
};

export const useNextLevelXp = () => {
  const { xp, level } = useAccountState();
  const nextXp = levels[level]
  const left = nextXp - xp
  return { nextXp, left }
}

export const useProgressionState = () => {
  const { level } = useAccountState();

  return {
    scoreUp: scoreUp[level] || scoreUp[scoreUp.length - 1],
    scoreBonus: scoreBonus[level] || scoreBonus[scoreBonus.length - 1],
  }
}