import { Accessor, Document, Node, NodeIO } from '@gltf-transform/core';
import { mat4, quat } from 'gl-matrix';

function createShakeAnimation(doc: Document, bones: Node[]) {
  const angle = 60;
  const moveBones = bones.slice(1);
  const anglePerBone = angle / moveBones.length;
  const left = quat.fromEuler(quat.create(), 0, 0, anglePerBone);
  const right = quat.fromEuler(quat.create(), 0, 0, -anglePerBone);

  const bone1Input = doc.createAccessor()
    .setType(Accessor.Type.SCALAR)
    .setArray(new Float32Array([0, 0.3, 0.65, 0.85, 3]))

  const bone1Output = doc.createAccessor()
    .setType(Accessor.Type.VEC4)
    .setArray(new Float32Array([
      0, 0, 0, 1,
      // @ts-ignore
      ...left,
      // @ts-ignore
      ...right,
      0, 0, 0, 1,
      0, 0, 0, 1,
    ]))
  

  const scs = moveBones.map((bone, i) => {
    const sampler = doc.createAnimationSampler().setInput(bone1Input).setOutput(bone1Output)
    const channel = doc.createAnimationChannel().setTargetNode(bone).setTargetPath('rotation').setSampler(sampler);
    return { sampler, channel };
  });

  const animation = doc.createAnimation('shake')
  scs.forEach(sc => {
    animation.addChannel(sc.channel).addSampler(sc.sampler);
  });
}

function mix(a: number, b: number, t: number) {
  return a * (1 - t) + b * t;
}

function createSqueezeAnimation(doc: Document, bones: Node[]) {
  const keys = bones.map((bone, i) => i / (bones.length - 1));

  const scaleDecrease = 0.7;
  const allScales = bones.map((_, i) => {
    // const t = i / (bones.length - 1);
    const scales = bones.map((_, j) => {
      const distance = Math.abs(i - j) / (bones.length - 1);
      return mix(scaleDecrease, 1.3, distance);
    });
    return scales;
  })

  const scs = bones.map((bone, i) => {
    // i - current time and current bone
    // 
    // compensate scale children bones onto parent bones
    const scalesOfBone = allScales[i];
    const scales = scalesOfBone.map((s, j) => {
      if (i === 0) return s;
      const parentScale = allScales[i - 1][j];
      return s / parentScale;
    })


    const boneInput = doc.createAccessor()
      .setType(Accessor.Type.SCALAR)
      .setArray(new Float32Array([
        0,
        0.1,
        ...keys.map(k => k + 0.2),
        1.3,
        1.4,
      ]));

    const scales3d = [
      ...[1, 1, 1],
      ...[1, 1, 1],
      ...scales.reverse().map(v => [v, v, v]).flat(),
      ...[1, 1, 1],
      ...[1, 1, 1],
    ];
    const boneOutput = doc.createAccessor()
      .setType(Accessor.Type.VEC3)
      .setArray(new Float32Array(scales3d));

    const sampler = doc.createAnimationSampler().setInput(boneInput).setOutput(boneOutput)
    const channel = doc.createAnimationChannel().setTargetNode(bone).setTargetPath('scale').setSampler(sampler);
    return { sampler, channel };
  });

  const animation = doc.createAnimation('squeeze')
  scs.forEach(sc => {
    animation.addChannel(sc.channel).addSampler(sc.sampler);
  });
}

export function createSkeleton(
  doc: Document,
  options: { height: number, bones: number }
) {
  const bones = Array.from({ length: options.bones }, (_, i) => i).map(i => {
    const positionY = i / (options.bones - 1) * options.height
    const bone = doc.createNode(`Bone${i}`)
      .setTranslation([0, positionY, 0])
      .setRotation([0, 0, 0, 1])
      .setScale([1, 1, 1]);
    return bone;
  });

  // Attach bones to each other.
  bones.forEach((bone, i) => {
    if (i > 0) {
      bones[i - 1].addChild(bone);
    }
  });

  bones.forEach((bone, i) => {
    if(i === 0) return;
    const positionY = options.height / options.bones;
    bone.setTranslation([0, positionY, 0]);
  });

  const inverseBindMatrices = new Float32Array(16 * options.bones);
  bones.forEach((bone, i) => {
    const worldMatrix = bone.getWorldMatrix();
    mat4.invert(inverseBindMatrices.subarray(i * 16, i * 16 + 16), worldMatrix);
  });

  const inverseBindAccessor = doc.createAccessor()
    .setArray(inverseBindMatrices)
    .setType(Accessor.Type.MAT4);

  const skeleton = doc.createSkin()
    .setInverseBindMatrices(inverseBindAccessor);
  bones.forEach(bone => skeleton.addJoint(bone));

  // Create animations
  const shakeAnimation = createShakeAnimation(doc, bones);
  const squeezeAnimation = createSqueezeAnimation(doc, bones);

  return {
    skeleton,
    bones,
    animations: [shakeAnimation, squeezeAnimation]
  };
}