import cfg from '../config';
import { Leader } from '../types/leader';
import * as auth from './auth';

type CollectLeaderDto = {
  score: number;
  xp: number;
  level: number;
}

export const get = async () => {
  const response = await fetch(`${cfg.apiUrl}/api/v1/states`, {
    headers: {
      Authorization: `Bearer ${auth.getToken()}`,
      ContentType: 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch leader');
  }
  return response.json() as Promise<Leader>;
}

export const push = async (data: CollectLeaderDto) => {
  const response = await fetch(`${cfg.apiUrl}/api/v1/states/push`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${auth.getToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error('Failed to collect leader');
  }
  return response.json() as Promise<Leader>;
}