import React from 'react';
import { useAccountState } from '../../providers/AccountStateProvider';
import { Typography as MuiTypography, TypographyProps, styled } from '@mui/material';

const Typography = styled(MuiTypography)({
  userSelect: 'none',
  pointerEvents: 'none',
  fontWeight: 'bold',
});

type AccountLevelProps = TypographyProps;
function AccountLevel({ ...props }: AccountLevelProps) {
  const { level } = useAccountState();
  return (
    <Typography {...props}>
      Level {level}
    </Typography>
  )
}

export default React.memo(AccountLevel);