import { NodeIO } from '@gltf-transform/core';
import { generate } from './generator'
import { Plane } from '../../types/plane';
import { randomArrayElement, randomRange } from '../utils';
import { getRandomColorHex } from '../colors';
import { v4 as uuid } from 'uuid';
import { BaseType, BodyType, HatType } from './types';

export async function generatePlaneBlop(plane: Plane) {
  const doc = await generate(plane);
  const io = new NodeIO();
  const arrayBuffer = await io.writeBinary(doc);
  const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
  return blob;
}

const hats: HatType[] = [
  'hat1',
  'hat2'
];

const bodies: BodyType[] = [
  'none',
];

const bases: BaseType[] = [
  'bomb'
];

export function generateRandomBallon() {
  const plane: Plane = {
    id: uuid(),
    length: randomRange(6, 10),
    width: randomRange(2.0, 2.5),
    ballsSize: randomRange(1.2, 2.0),
    color: getRandomColorHex(),
    hatColor: getRandomColorHex(),
    metallic: Math.random(),
    roughness: Math.random(),
    hardness: randomRange(0, 1),
    hatType: Math.random() > 0.85 ? randomArrayElement(hats) : 'none',
    bodyType: Math.random() > 0.85 ? randomArrayElement(bodies) : 'none',
    baseType: Math.random() > 0.85 ? randomArrayElement(bases) : 'none',
  }
  return plane;
}