import React from 'react';
import { Box, BoxProps, LinearProgress, linearProgressClasses, styled } from '@mui/material';
import { levels } from '../../models/levels';
import { useAccountState, useNextLevelXp } from '../providers/AccountStateProvider';

const Root = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 1,
  alignItems: 'center',
});

// linean gradient 3 colors
// FF7900
// 35CA33
// 1C202C
// 'linear-gradient(90deg, #FF7900 0%, #35CA33 50%, #1C202C 100%)',

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 9,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    background: 'linear-gradient(90deg, #FF7900 0%, #35CA33 50%, #1C202C 100%)',
    borderRadius: 5,
  },
}));

type XPProgressProps = BoxProps;
function XPProgress({ ...props }: XPProgressProps) {
  const { xp } = useAccountState();
  const { nextXp } = useNextLevelXp();
  const progress = xp / nextXp * 100;
  return (
    <Root {...props}>
      <BorderLinearProgress variant="determinate" value={progress}  sx={{ flexGrow: 1 }} />
    </Root>
  )
}

export default React.memo(XPProgress);