import { Box, BoxProps, styled } from '@mui/material';
import React from 'react';

export type InfoBoxesContainerProps = BoxProps;
export const InfoBoxesContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
  margin: '20px 0',
  width: '100%',
});

export const InfoBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '10px',
  padding: '10px 20px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const InfoBoxHeader = styled('div')(({ color }) => ({
  fontSize: '11px',
  fontWeight: 500,
  marginBottom: '5px',
  color: color
}));

export const InfoBoxContent = styled('div')({
  fontSize: '11px',
  fontWeight: 600,
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
});