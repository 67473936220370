import { Button, ButtonProps } from '@mui/material';
import { bounceAnimationCss } from '../../lib/animations';

export type BounceButtonProps = ButtonProps & {
  duration?: string;
};

const BounceButton: React.FC<BounceButtonProps> = ({ duration = '0.15s', sx, ...props }) => {
  return (
    <Button
      {...props}
      sx={{ ...bounceAnimationCss(duration), ...sx }}
    />
  );
};

export default BounceButton;