import React from 'react';
import { usePlanes } from '../providers/PlanesProvider';
import GoldButton, { GoldButtonProps } from '../dumps/GoldButton';

export type RegenButtonProps = Omit<GoldButtonProps, 'onClick'>;

const RegenButton: React.FC<RegenButtonProps> = ({ sx, ...props }) => {
  const { regenerate } = usePlanes();
  return (
    <GoldButton
      sx={{ color: 'white', ...sx }}
      onClick={regenerate}
      fullWidth
      {...props}
    >
      Generate new
    </GoldButton>
  );
};

export default RegenButton;