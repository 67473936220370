import React, {
  createContext, useCallback, useContext,
  useEffect,
  useState,
} from 'react';
import { Plane } from '../../types/plane';
import { randomRange } from '../../lib/utils';
import { getRandomColorHex } from '../../lib/colors';
import { v4 as uuid } from 'uuid';
import { generateRandomBallon } from '../../lib/planeGen';

type PlanesContextType = {
  planes: Plane[],
  regenerate: () => void,
};

export const PlanesContext = createContext<PlanesContextType>({
  planes: [],
  regenerate: () => { throw new Error('') },
});


type Props = {
  children: React.ReactNode,
  initAmount?: number,
  disabled?: boolean,
};


function PlanesProvider({ children, initAmount, disabled }: Props) {
  const [planes, setPlanes] = useState<Plane[]>([]);

  
  const handleRegenerate = useCallback(() => {
    const planes = Array.from({ length: initAmount || 100 }).map(generateRandomBallon);
    setPlanes(planes);
  }, [initAmount])

  useEffect(() => {
    if(disabled) return;
    handleRegenerate();
  }, [disabled, handleRegenerate]);

  return (
    <PlanesContext.Provider
      value={{
        planes,
        regenerate: handleRegenerate
      }}
    >
      {children}
    </PlanesContext.Provider>
  );
}

const usePlanes = () => useContext(PlanesContext);

const usePlane = (id: string) => {
  const { planes } = usePlanes();
  return planes.find(plane => plane.id === id);
}

export { PlanesProvider, usePlanes, usePlane };