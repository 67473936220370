export function randomRange(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export function mix(a: number, b: number, t: number) {
  return a * (1 - t) + b * t;
}

export function randomInt(min: number, max: number) {
  return Math.floor(randomRange(min, max));
}

export function randomArrayElement<T>(array: T[]): T {
  return array[randomInt(0, array.length)];
}