import { SvgIcon, SvgIconProps } from "@mui/material";
import { bounceAnimationCss } from "../../../lib/animations";

export type BigCoinProps = SvgIconProps & {
  duration?: string;
};
export default function BigCoin({ sx, duration = '0.15s', ...props}: BigCoinProps) {
  return (
    <SvgIcon {...props} sx={{...bounceAnimationCss(duration), ...sx}}>
      <svg width="138" height="200" viewBox="0 0 138 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M165.527 67.6027C183.035 91.8049 172.288 129.218 140.363 152.313C108.583 175.302 70.6837 172.85 53.0939 148.534C35.3457 124 43.7468 84.1459 75.2618 61.3482C106.779 38.5491 147.78 43.0696 165.527 67.6027Z" stroke="url(#paint0_linear_36_1043)" strokeWidth="9.59964"/>
        <path d="M161.668 53.3009C188.475 85.0691 181.551 123.481 144.259 153.746C105.862 182.059 64.2097 175.229 42.3167 147.382C20.4237 119.535 28.4533 73.2469 62.4531 48.6517C96.4529 24.0565 141.498 25.4188 161.668 53.3009Z" fill="url(#paint1_linear_36_1043)"/>
        <path d="M156.214 53.6739C165.006 65.8276 166.535 81.1986 161.696 96.7398C156.847 112.316 145.631 127.836 129.388 139.586C96.5936 163.309 58.0192 161.217 40.4904 136.986C22.8066 112.54 31.7718 72.1427 64.2989 48.6128C96.828 25.0815 138.531 29.2298 156.214 53.6739Z" stroke="url(#paint2_linear_36_1043)" strokeWidth="9.59964"/>
        <path d="M145.987 55.4377C158.824 73.1834 152.577 106.307 121.958 128.457C91.3376 150.607 57.923 146.174 45.0858 128.429C32.2486 110.683 38.4951 77.5594 69.1151 55.4091C99.735 33.2589 133.15 37.6919 145.987 55.4377Z" fill="#FFD93B" stroke="url(#paint3_linear_36_1043)" strokeWidth="26.6657"/>
        <path d="M141.832 58.7719C148.188 67.5585 149.074 79.2838 145.017 91.3428C140.961 103.397 132.003 115.6 119.027 124.987C106.051 134.374 91.6579 139.062 78.9399 139.142C66.2167 139.222 55.3577 134.711 49.0016 125.925C42.6454 117.138 41.759 105.413 45.8167 93.3536C49.8728 81.2994 58.8302 69.0964 71.8062 59.7097C84.7822 50.323 99.1754 45.6343 111.893 45.5542C124.617 45.4741 135.476 49.9853 141.832 58.7719Z" stroke="url(#paint4_linear_36_1043)" strokeWidth="4.26651"/>
        <defs>
        <linearGradient id="paint0_linear_36_1043" x1="165.674" y1="59.5481" x2="66.3696" y2="171.182" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FDF8CE"/>
        <stop offset="0.0550575" stopColor="#F2E158"/>
        <stop offset="0.170676" stopColor="#FCF5AF"/>
        <stop offset="0.309086" stopColor="#ECD42F"/>
        <stop offset="0.491314" stopColor="#E9B00C"/>
        <stop offset="0.704021" stopColor="#ECB600"/>
        <stop offset="0.78935" stopColor="#E8CF29"/>
        <stop offset="0.843399" stopColor="#EBD431"/>
        <stop offset="0.909367" stopColor="#E2C317"/>
        <stop offset="1" stopColor="#E9D14F"/>
        </linearGradient>
        <linearGradient id="paint1_linear_36_1043" x1="163.968" y1="60.2743" x2="54.1847" y2="171.136" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EEBB5C"/>
        <stop offset="0.0550575" stopColor="#FDEE48"/>
        <stop offset="0.170676" stopColor="#E8BB06"/>
        <stop offset="0.309086" stopColor="#D57B01"/>
        <stop offset="0.491314" stopColor="#C86101"/>
        <stop offset="0.660555" stopColor="#D88C2D"/>
        <stop offset="0.771761" stopColor="#E8BE29"/>
        <stop offset="0.815114" stopColor="#E8BE29"/>
        <stop offset="0.881945" stopColor="#E8AE0E"/>
        <stop offset="0.930202" stopColor="#D98317"/>
        <stop offset="1" stopColor="#EBAE4E"/>
        </linearGradient>
        <linearGradient id="paint2_linear_36_1043" x1="156.362" y1="45.6187" x2="55.1294" y2="160.751" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FDF8CE"/>
        <stop offset="0.0550575" stopColor="#F2E158"/>
        <stop offset="0.170676" stopColor="#FCF5AF"/>
        <stop offset="0.309086" stopColor="#ECD42F"/>
        <stop offset="0.491314" stopColor="#E9B00C"/>
        <stop offset="0.704021" stopColor="#ECB600"/>
        <stop offset="0.78935" stopColor="#E8CF29"/>
        <stop offset="0.843399" stopColor="#EBD431"/>
        <stop offset="0.909367" stopColor="#E2C317"/>
        <stop offset="1" stopColor="#E9D14F"/>
        </linearGradient>
        <linearGradient id="paint3_linear_36_1043" x1="124.055" y1="26.1966" x2="74.816" y2="157.845" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFDC5E"/>
        <stop offset="0.417119" stopColor="#FFED97"/>
        <stop offset="1" stopColor="#FFF27B"/>
        </linearGradient>
        <linearGradient id="paint4_linear_36_1043" x1="71.2062" y1="55.3311" x2="124.214" y2="125.544" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E4991A"/>
        <stop offset="0.474971" stopColor="#EAAA2E" stopOpacity="0"/>
        <stop offset="1" stopColor="#FFF27B" stopOpacity="0"/>
        </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
}