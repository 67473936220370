import React, { createContext, useCallback, useEffect, useState } from 'react';
import type { Balloon } from '../../types/plane';
import { useNavigate } from 'react-router-dom';

type AccountBallonContextType = {
  loaded: boolean;
  hashId?: string;
  balloon?: Balloon ;
  setBalloon: (balloon: Balloon) => void;
  resetBalloon: () => void;
}

const AccountBallonContext = createContext<AccountBallonContextType>({
  loaded: false,
  setBalloon: () => { throw new Error('AccountBallonContext is not defined'); },
  resetBalloon: () => { throw new Error('AccountBallonContext is not defined'); },
});

function path2plane(path: string) {
  const blocks = path.split('?');
  const b = blocks.length > 1 ? blocks[1] : '';
  const c = b.replace('plane=', '');
  return c;
}

export type AccountBallonProviderProps = {
  children: React.ReactNode;
};

export const AccountBallonProvider: React.FC<AccountBallonProviderProps> = ({ children }) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [hashId, setHashId] = useState<string | undefined>(undefined);
  const [ballon, setBallon] = useState<Balloon | undefined>(undefined);

  useEffect(() => {
    try {
      const savedBalloon = localStorage.getItem('balloon');
      if(savedBalloon) {
        const newHashId = encodeURI(savedBalloon)
        setHashId(newHashId);
        setBallon(JSON.parse(savedBalloon) as Balloon);
      }
    } catch (e) {
      console.error(e);
    }
    setLoaded(true);
  }, []);

  const handleSetBalloon = useCallback((balloon: Balloon) => {
    const stringBalloon = JSON.stringify(balloon);
    localStorage.setItem('balloon', stringBalloon);
    setHashId(encodeURI(stringBalloon));
    setBallon(balloon);
  }, []);

  const handleResetAccount = useCallback(() => {
    localStorage.removeItem('balloon');
    setHashId(undefined);
    setBallon(undefined);
  }, []);

  return (
    <AccountBallonContext.Provider value={{
      loaded: loaded,
      hashId: hashId,
      balloon: ballon,
      setBalloon: handleSetBalloon,
      resetBalloon: handleResetAccount,
    }}>
      {children}
    </AccountBallonContext.Provider>
  );
};

export const useBalloon = () => {
  return React.useContext(AccountBallonContext);
};

export type BalloonGuardProps = {
  children: React.ReactNode;
};

export const BalloonGuard: React.FC<BalloonGuardProps> = ({ children }) => {
  const { loaded, hashId } = useBalloon();
  const navigate = useNavigate();
  useEffect(() => {
    if (loaded && !hashId) {
      navigate('/gallery');
    }
  }, [navigate, loaded, hashId]);

  return <>{children}</>;
}