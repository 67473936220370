import { SvgIcon, SvgIconProps } from "@mui/material";

export default function MineIcon({ htmlColor, ...props}: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.35708 16.7838C7.30082 15.7382 7.33474 14.0337 8.35708 12.998L9.4196 11.9265L12.6161 8.73005C12.6822 8.66844 12.7527 8.59166 12.8304 8.55148C13.3433 8.13197 13.9937 7.91795 14.6554 7.95095C15.3172 7.98395 15.9431 8.26161 16.4117 8.73005L20.635 12.9533C20.9636 13.2855 22.0547 14.3819 22.3681 14.6945C22.8348 15.1634 23.1121 15.7881 23.1467 16.4488C23.1813 17.1095 22.9708 17.7598 22.5556 18.2749C22.4529 18.3981 21.4439 19.416 21.3055 19.5517L19.1805 21.6776L18.1091 22.7491C17.118 23.7866 15.3126 23.7875 14.3224 22.7491L8.35708 16.7838ZM6.91063 11.9167C6.37761 11.2881 6.10015 10.4822 6.13322 9.65866C6.1663 8.83513 6.50748 8.05407 7.0892 7.47021C8.27672 6.28268 10.2768 6.22018 11.5446 7.2827C11.4821 7.3452 11.4107 7.40771 11.3571 7.47021L6.91063 11.9167ZM22.5645 20.8115C22.694 20.6535 23.7163 19.6812 23.8234 19.5436L49.0418 44.7522C50.3195 45.987 50.3213 48.1799 49.0418 49.4139C47.806 50.6916 45.6158 50.6933 44.381 49.4139L19.1716 24.2045C19.2519 24.133 19.3144 24.0705 19.368 24.008L22.5645 20.8115ZM2.33911 30.0626C3.73476 26.1387 5.67328 22.4296 8.09815 19.0436L12.6875 23.633C8.32315 28.8153 4.62487 34.6082 1.69535 40.6985C1.54356 41.02 1.23106 41.2075 0.891762 41.2075C0.377468 41.2146 -0.0645065 40.7449 0.00781631 40.2164C0.383667 36.7521 1.16674 33.3441 2.33911 30.0626ZM40.3175 2.06832C34.1961 5.00677 28.4661 8.69917 23.2609 13.0596C22.3707 12.1578 19.6091 9.40417 18.6716 8.47023C24.9204 4.01281 32.2057 1.22805 39.8345 0.380788C40.8077 0.254893 41.2193 1.65313 40.3175 2.06832Z" fill={htmlColor}/>
      </svg>
    </SvgIcon>
  );
}