import { createTheme } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import { darken } from '@mui/material';

const theme = createTheme({


  palette: {
    //mode: "light",
    mode: "dark",
    primary: {
      // main: purple[500],
      main: '#9c27b0',
    },
    secondary: {
      main: '#f44336',
    },
    background: {
      paper: '#30343A',
    },
    text: {
      //primary: '#ffffff',
    },
    divider: 'rgba(255, 255, 255, 0.2)',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          position: fixed;
          bottom: 0;
          padding: 0;
          margin: 0;
          overflow: hidden;
          height: 100vh;
          width: 100%;
          background: linear-gradient(180deg, #2C2F35 0%, #1A1F2B 100%);
          background-color: #1A1F2B;
          font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
            Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      `,
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#30343A',
          borderRadius: 12,
          boxShadow: 'none',
        },
      }, 
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    // Select rounded corners
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    /*
    MuiContainer: {
      styleOverrides: {
        root: {
          // Устанавливаем padding в 0 на экранах меньше sm (600px)
          '@media (max-width:600px)': {
            paddingLeft: 0,
            paddingRight: 0,
          }
        }
      }
    },
    */
  },
});

export default theme;