import {
  Accessor,
  Document,
  NodeIO,
  vec3 as gvec3,
  vec4 as gvec4,
} from '@gltf-transform/core';
import { Balloon, BaseType, BodyType, HatType } from './types';
import { hex2Array } from './colors';
import { createSkeleton } from './skeleton';
import { DocGenerator } from './gltf';
import { vec3 } from 'gl-matrix';
import { CylinderPrimitive } from './primitives/CylinderPrimitive';
import { SpherePrimitive } from './primitives/SpherePrimitive';
import { HemispherePrimitive } from './primitives/HemispherePrimitive';
import { Mesh, PrimitiveData, megrePrimitives, offsetPrimitive, toMesh } from './primitives/Mesh';
import { Transform } from './common';

const numBones = 8;
const hatModels = {
  hat1: '/models/hat.glb',
  hat2: '/models/hatCyl.glb',
}
const baseModels = {
  bomb: '/models/bomb.glb',
}

function loadGlbMeshFromUrl(url: string) {
  return fetch(url)
    .then(res => res.arrayBuffer())
    .then(arrayBuffer => {
      const io = new NodeIO();
      const uint8Array = new Uint8Array(arrayBuffer);
      return io.readBinary(uint8Array);
    });
}

export type Props = {
  length: number;
  width: number;
  ballsSize: number;
  bones: number;
  hatType: HatType;
  bodyType: BodyType;
  baseType: BaseType;
};

const smoothSegments = 32;

export async function generateBodyMesh(props: Props): Promise<Mesh> {
  const { length, width, ballsSize, bones, baseType } = props;

  const centerRadius = width / 2;
  const numHeightSegments = Math.round(length + 0.5) * 2;

  // Create cylinder and attach to bones
  const cylinder = new CylinderPrimitive(
    { height: length, radius: centerRadius, radialSegments: smoothSegments, heightSegments: numHeightSegments },
    { bones }
  ).toData();

  // Create hemisphere and attach to last bone
  const hemisphere = new HemispherePrimitive(
    { radius: centerRadius, widthSegments: smoothSegments, heightSegments: smoothSegments },
    bones - 1
  ).toData();

  const hemisphereOffset: vec3 = [0, length, 0];
  const mergedPrimitive = megrePrimitives([
    cylinder,
    offsetPrimitive(hemisphere, hemisphereOffset),
  ]);

  return toMesh(mergedPrimitive);
}

async function generateBodyPrimitive(docGen: DocGenerator, props: Balloon) {
  const balloonMesh = await generateBodyMesh({
    length: props.length,
    width: props.width,
    ballsSize: props.ballsSize,
    bones: numBones,
    hatType: props.hatType || 'none',
    bodyType: props.bodyType || 'none',
    baseType: props.baseType || 'none',
  });

  const prim = docGen.createSkinPrimitive(balloonMesh, {
    color: hex2Array(props.color),
    metallic: props.metallic,
    roughness: props.roughness,
  }, 'BalloonPrimitive')

  return prim;
}

async function generateBottomPrimitive(docGen: DocGenerator, props: Balloon, transform: Transform, name: string) {
  const sphere0 = new SpherePrimitive({ radius: 1, widthSegments: 32, heightSegments: 32 }, 0);
  const prim0 = docGen.createPrimitive(sphere0.toMesh(), {
    color: hex2Array(props.color),
    metallic: props.metallic,
    roughness: props.roughness,
  }, name);

  const node = docGen.doc.createNode()
    .setTranslation(transform.position)
    .setRotation(transform.rotation)
    .setScale(transform.scale)
    .setMesh(docGen.doc.createMesh().addPrimitive(prim0));
  return node
}

const bottoms = {
  bomb: async (docGen: DocGenerator, props: Balloon) => {
    const scale = props.ballsSize
    const bottom0Transform: Transform = {
      position: [-scale * 0.8, 0, 0],
      rotation: [0, 0, 0, 1],
      scale: [scale, scale, scale]
    }
    const bottom1Transform: Transform = {
      position: [scale * 0.8, 0, 0],
      rotation: [0, 0, 0, 1],
      scale: [scale, scale, scale]
    }

    const bombDoc0 = await loadGlbMeshFromUrl(baseModels['bomb']);
    const bombDoc1 = await loadGlbMeshFromUrl(baseModels['bomb']);
    const bNode0 = docGen.copyFrom(bombDoc0, bottom0Transform);
    const bNode1 = docGen.copyFrom(bombDoc1, bottom1Transform);

    return [bNode0, bNode1];
  },
  none: async (docGen: DocGenerator, props: Balloon) => {
    const scale = props.ballsSize
    const bottom0Transform: Transform = {
      position: [-1, 0, 0],
      rotation: [0, 0, 0, 1],
      scale: [scale, scale, scale]
    }
    const bottom1Transform: Transform = {
      position: [1, 0, 0],
      rotation: [0, 0, 0, 1],
      scale: [scale, scale, scale]
    }

    const bNode0 = await generateBottomPrimitive(docGen, props, bottom0Transform, 'BottomPrimitive0');
    const bNode1 = await generateBottomPrimitive(docGen, props, bottom1Transform, 'BottomPrimitive1');

    return [bNode0, bNode1];
  }
}

async function hatByType(docGen: DocGenerator, type: HatType, props: Balloon) {
  if(type === 'none') return undefined;

  const radius = props.width * 1.5;
  const bottom0Transform: Transform = {
    position: [0, 1.5, 0],
    rotation: [0, 0, 0, 1],
    scale: [radius, radius, radius]
  }

  const bombDoc0 = await loadGlbMeshFromUrl(hatModels[type]);
  const bNode0 = docGen.copyFrom(bombDoc0, bottom0Transform, props.hatColor ? {
    color: hex2Array(props.hatColor),
    metallic: props.metallic,
    roughness: props.roughness,
  } : undefined);

  return bNode0;
}

function bottomByType(docGen: DocGenerator, type: BaseType, props: Balloon) {
  const generator = bottoms[type] || bottoms.none;
  return generator(docGen, props);
}


export async function generate(props: Balloon) {
  const gen = new DocGenerator();
  const bodyPrimitive = await generateBodyPrimitive(gen, props);
  const mainNode = gen.doc.createNode()
  const mesh = gen.doc.createMesh().addPrimitive(bodyPrimitive);
  const [bNode0, bNode1] = await bottomByType(gen, props.baseType || 'none', props)

  const hatNode = await hatByType(gen, props.hatType || 'none', props);

  // Create skeleton
  const { skeleton, bones, animations } = createSkeleton(gen.doc, {
    height: props.length,
    bones: numBones
  });
  mainNode
    .setMesh(mesh)
    .setSkin(skeleton);

  bones[0].addChild(bNode0);
  bones[0].addChild(bNode1);

  if(hatNode) {
    bones[bones.length - 1].addChild(hatNode);
  }

  const scene = gen.doc.createScene()
    .addChild(mainNode)
    .addChild(bones[0]);

  return gen.toDocument();
}