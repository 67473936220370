import React, { useState } from 'react';
import MobilePage from '../components/layout/MobilePage';

import { Tab, TabsContainer, TabsContainerProps } from '../components/dumps/Tabs';
import FriendList from '../components/containers/FriendList';
import TopList from '../components/containers/TopList';
import { bounceAnimationCss } from '../lib/animations';
import InviteFriendButton from '../components/containers/InviteFriendButton';
// import dynamic from 'next/dynamic';
// const InviteFriendButton = dynamic(() => import('../components/containers/InviteFriendButton'), { ssr: false });

type TabType = 'friends' | 'top';
const defaultTab: TabType = 'top';

type TabsProps = TabsContainerProps & {
  activeTab: TabType;
  setActiveTab: (tab: TabType) => void;
};
const Tabs: React.FC<TabsProps> = ({ activeTab, setActiveTab, ...props }) => {
  return (
    <TabsContainer {...props}>
      <Tab
        isActive={activeTab === 'friends'}
        onClick={() => setActiveTab('friends')}
        sx={{ ...bounceAnimationCss('0.2s') }}
      >
        FRIENDS
      </Tab>
      <Tab
        isActive={activeTab === 'top'}
        onClick={() => setActiveTab('top')}
        sx={{ ...bounceAnimationCss('0.25s') }}
      >
        TOP
      </Tab>
    </TabsContainer>
  );
};

const Friends: React.FC = () => {
  const [activeTab, setActiveTab] = useState<TabType>(defaultTab);
  return (
    <MobilePage title='Friends'>
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab}/>
      {
        activeTab === 'friends' ? (
          <FriendList sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            flexGrow: 1
          }}/>
        ) : (
          <TopList sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            flexGrow: 1
          }}/>
        )
      }
      <InviteFriendButton
        variant='contained'
        color='primary'
        sx={{
          marginTop: 1,
          marginBottom: 2,
        }}
        fullWidth
      />
    </MobilePage>
  );
};

export default Friends;