import { SvgIcon, SvgIconProps } from "@mui/material";
import { bounceAnimationCss } from "../../../lib/animations";

export type SmallCoinProps = SvgIconProps & {
  duration?: string;
};

export default function SmallCoin({ sx, duration = '0.15s', ...props}: SmallCoinProps) {
  return (
    <SvgIcon {...props} sx={{ ...bounceAnimationCss(duration), ...sx }}>
      <svg width="71" height="120" viewBox="0 0 71 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M-28.7698 40.7492C-39.1281 55.0682 -32.8223 77.3265 -13.7431 91.1283C5.23963 104.86 27.7586 103.328 38.1716 88.9331C48.6902 74.3924 43.7484 50.6722 24.9426 37.0682C6.13552 23.4633 -18.252 26.2095 -28.7698 40.7492Z" stroke="url(#paint0_linear_36_996)" strokeWidth="6.39976"/>
        <path d="M-26.7133 31.9805C-42.7976 51.0414 -38.6432 74.0889 -16.268 92.2478C6.77031 109.235 31.7614 105.137 44.8972 88.4293C58.033 71.7213 53.2153 43.9482 32.8154 29.191C12.4155 14.4339 -14.6115 15.2513 -26.7133 31.9805Z" fill="url(#paint1_linear_36_996)"/>
        <path d="M-23.1818 32.3919C-28.3863 39.5865 -29.3044 48.6998 -26.4249 57.9488C-23.538 67.2214 -16.8541 76.4781 -7.15763 83.4925C12.4403 97.6696 35.3615 96.3425 45.7338 82.004C56.2095 67.5226 50.9405 43.4753 31.5204 29.427C12.0991 15.3777 -12.7069 17.9116 -23.1818 32.3919Z" stroke="url(#paint2_linear_36_996)" strokeWidth="6.39976"/>
        <path d="M-16.5845 33.7836C-23.8821 43.8716 -20.5241 63.2188 -2.36611 76.3542C15.7919 89.4895 35.2182 86.6243 42.5158 76.5363C49.8134 66.4483 46.4554 47.101 28.2974 33.9657C10.1394 20.8303 -9.2869 23.6955 -16.5845 33.7836Z" fill="#FFD93B" stroke="url(#paint3_linear_36_996)" strokeWidth="17.7771"/>
        <path d="M-14.6964 35.3465C-18.4761 40.5716 -19.012 47.5558 -16.5877 54.7604C-14.1646 61.9618 -8.80981 69.2602 -1.04544 74.8769C6.71892 80.4936 15.3265 83.2954 22.9245 83.3432C30.5259 83.3911 36.9915 80.6965 40.7713 75.4714C44.5511 70.2464 45.0869 63.2622 42.6627 56.0576C40.2395 48.8562 34.8848 41.5578 27.1204 35.9411C19.356 30.3244 10.7485 27.5226 3.1505 27.4748C-4.45088 27.4269 -10.9166 30.1215 -14.6964 35.3465Z" stroke="url(#paint4_linear_36_996)" strokeWidth="2.84434"/>
        <defs>
        <linearGradient id="paint0_linear_36_996" x1="-29.1173" y1="35.7289" x2="30.4654" y2="102.709" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FDF8CE"/>
        <stop offset="0.0550575" stopColor="#F2E158"/>
        <stop offset="0.170676" stopColor="#FCF5AF"/>
        <stop offset="0.309086" stopColor="#ECD42F"/>
        <stop offset="0.491314" stopColor="#E9B00C"/>
        <stop offset="0.704021" stopColor="#ECB600"/>
        <stop offset="0.78935" stopColor="#E8CF29"/>
        <stop offset="0.843399" stopColor="#EBD431"/>
        <stop offset="0.909367" stopColor="#E2C317"/>
        <stop offset="1" stopColor="#E9D14F"/>
        </linearGradient>
        <linearGradient id="paint1_linear_36_996" x1="-28.0933" y1="36.1646" x2="37.7764" y2="102.682" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EEBB5C"/>
        <stop offset="0.0550575" stopColor="#FDEE48"/>
        <stop offset="0.170676" stopColor="#E8BB06"/>
        <stop offset="0.309086" stopColor="#D57B01"/>
        <stop offset="0.491314" stopColor="#C86101"/>
        <stop offset="0.660555" stopColor="#D88C2D"/>
        <stop offset="0.771761" stopColor="#E8BE29"/>
        <stop offset="0.815114" stopColor="#E8BE29"/>
        <stop offset="0.881945" stopColor="#E8AE0E"/>
        <stop offset="0.930202" stopColor="#D98317"/>
        <stop offset="1" stopColor="#EBAE4E"/>
        </linearGradient>
        <linearGradient id="paint2_linear_36_996" x1="-23.5298" y1="27.3712" x2="37.2097" y2="96.4507" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FDF8CE"/>
        <stop offset="0.0550575" stopColor="#F2E158"/>
        <stop offset="0.170676" stopColor="#FCF5AF"/>
        <stop offset="0.309086" stopColor="#ECD42F"/>
        <stop offset="0.491314" stopColor="#E9B00C"/>
        <stop offset="0.704021" stopColor="#ECB600"/>
        <stop offset="0.78935" stopColor="#E8CF29"/>
        <stop offset="0.843399" stopColor="#EBD431"/>
        <stop offset="0.909367" stopColor="#E2C317"/>
        <stop offset="1" stopColor="#E9D14F"/>
        </linearGradient>
        <linearGradient id="paint3_linear_36_996" x1="-4.14528" y1="15.718" x2="25.3979" y2="94.7069" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFDC5E"/>
        <stop offset="0.417119" stopColor="#FFED97"/>
        <stop offset="1" stopColor="#FFF27B"/>
        </linearGradient>
        <linearGradient id="paint4_linear_36_996" x1="27.5638" y1="33.1987" x2="-4.24085" y2="75.3262" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E4991A"/>
        <stop offset="0.474971" stopColor="#EAAA2E" stopOpacity="0"/>
        <stop offset="1" stopColor="#FFF27B" stopOpacity="0"/>
        </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
}