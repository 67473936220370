import { vec3 } from "gl-matrix";

export type PrimitiveData = {
  vertices: vec3[];
  normals: vec3[];
  uvs: number[];
  indices: number[];
  skinWeights: number[];
  skinIndices: number[];
};

export type Mesh = {
  vertices: number[];
  normals: number[];
  triangles: number[];
  uvs: number[];
  skinWeights: number[];
  skinIndices: number[];
};

export type BoneInfo = {
  bone: number;
  height: number;
};

export type SkinData = {
  bones: number;
};

export function offsetVertices(vertices: vec3[], offset: vec3): vec3[] {
  return vertices.map(v => vec3.add(v, v, offset))
}

export function offsetPrimitive(primitive: PrimitiveData, offset: vec3): PrimitiveData {
  return {
    ...primitive,
    vertices: offsetVertices(primitive.vertices, offset),
  };
}

export function megrePrimitives(meshes: PrimitiveData[]): PrimitiveData {
  const vertices: vec3[] = [];
  const normals: vec3[] = [];
  const uvs: number[] = [];
  const indices: number[] = [];
  const skinWeights: number[] = [];
  const skinIndices: number[] = [];

  let vertexOffset = 0;
  let indexOffset = 0;

  for (const mesh of meshes) {
    vertices.push(...mesh.vertices);
    normals.push(...mesh.normals);
    uvs.push(...mesh.uvs);
    skinWeights.push(...mesh.skinWeights);
    skinIndices.push(...mesh.skinIndices);

    for (const index of mesh.indices) {
      indices.push(index + vertexOffset);
    }

    vertexOffset += mesh.vertices.length;
    indexOffset += mesh.indices.length;
  }

  return {
    vertices,
    normals,
    uvs,
    indices,
    skinWeights,
    skinIndices,
  };
}

export function toMesh(primitive: PrimitiveData): Mesh {
  return {
    vertices: primitive.vertices.flat() as number[],
    normals: primitive.normals.flat() as number[],
    triangles: primitive.indices,
    uvs: primitive.uvs,
    skinWeights: primitive.skinWeights,
    skinIndices: primitive.skinIndices,
  };
}
