import cfg from '../config';
import { Leader } from '../types/leader';
import * as auth from './auth';

export const fetchFriends = async () => {
  const response = await fetch(`${cfg.apiUrl}/api/v1/friends`, {
    headers: {
      Authorization: `Bearer ${auth.getToken()}`,
      ContentType: 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch leaders');
  }
  return response.json() as Promise<Leader[]>;
}

export const fetchLeaders = async () => {
  const response = await fetch(`${cfg.apiUrl}/api/v1/leaders`, {
    headers: {
      Authorization: `Bearer ${auth.getToken()}`,
      ContentType: 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch leaders');
  }
  return response.json() as Promise<Leader[]>;
}