import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
// import { useInitData } from '@tma.js/sdk-react';
import { isTMA, retrieveLaunchParams  } from '@tma.js/sdk';
import * as api from '../../api/tma-auth';
import { User } from '../../types/user';

type AccountContextType = {
  loaded: boolean;
  user?: User;
  signUp: (name: string) => Promise<User>;
  resetAccount: () => void;
}

function getLocalUser() {
  const savedUser2String = localStorage.getItem('user2');
  if (savedUser2String) {
    const localId = localStorage.getItem('localId');
    const user =  JSON.parse(savedUser2String);
    return { user, localId, legacy: false }
  }

  const savedUserString = localStorage.getItem('user');
  if (savedUserString) {
    const user = JSON.parse(savedUserString);
    return { user, localId: undefined, legacy: true }
  }

  return { user: undefined, localId: undefined, legacy: false }
}


const AccountContext = createContext<AccountContextType>({
  loaded: false,
  signUp: async () => { throw new Error('AccountContext is not defined'); },
  resetAccount: () => { throw new Error('AccountContext is not defined'); },
});

export type AccountProviderProps = {
  children: React.ReactNode;
};

export const AccountProvider: React.FC<AccountProviderProps> = ({ children }) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>(undefined);


  useEffect(() => {
    const init = async () => {
      try {
        const _isTMA = await isTMA()
        if (_isTMA) {
          const { initDataRaw } = retrieveLaunchParams();
          if (!initDataRaw) {
            throw new Error('initDataRaw is not defined');
          }
          
          const user = await api.signIn(initDataRaw);
          setUser(user);
        } else {
          const { user: savedUser, localId, legacy } = getLocalUser();
          if (savedUser) {
            if (!legacy && localId) {
              const user = await api.unsafeSignIn({ name: savedUser.name, token: localId });
              setUser(user);
            } else {
              const user = await api.unsafeSignIn({ name: savedUser.name, token: savedUser.id });
              setUser(user);
            }
          }
        }
      } catch (error) {
        const savedUserString = localStorage.getItem('user');
        if (savedUserString) {
          const savedUser = JSON.parse(savedUserString);
          setUser(savedUser);
        }
      } finally {
        setLoaded(true);
      }
    }

    init();
  }, []);

  const handleSingUp = useCallback(async (name: string) => {
    const localId = uuidv4();
    const user = await api.unsafeSignIn({ name, token: localId });
    setUser(user);

    localStorage.setItem('localId', localId);
    localStorage.setItem('user2', JSON.stringify(user));
    return user;
  }, []);

  const handleResetAccount = useCallback(() => {
    localStorage.removeItem('localId');
    localStorage.removeItem('user2');
    localStorage.removeItem('user');
    api.signOut();
    setUser(undefined);
  }, []);

  return (
    <AccountContext.Provider value={{
      loaded,
      user,
      signUp: handleSingUp,
      resetAccount: handleResetAccount,
    }}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  return React.useContext(AccountContext);
};

export type AccountGuardProps = {
  children: React.ReactNode;
};

export const AccountGuard: React.FC<AccountGuardProps> = ({ children }) => {
  const { loaded, user } = useAccount();
  const navigate = useNavigate();
  useEffect(() => {
    if (loaded && !user) {
      navigate('/signin');
    }
  }, [navigate, loaded, user]);

  return <>{children}</>;
}