import React from 'react';
import { Box, BoxProps, styled } from '@mui/material';

export type TabsContainerProps = BoxProps;
export const TabsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  minHeight: 39,
  margin: theme.spacing(2, 0),
  gap: theme.spacing(1),
}));

export type TabProps = React.ComponentProps<'button'>;
export const Tab = styled('button')<{
  isActive?: boolean,
  hoverColor?: string,
  activeColor?: string,
}>(({
  theme,
  isActive,
  hoverColor,
  activeColor,
}) => ({
  backgroundColor: '#1e1e1e',
  border: '1px solid transparent',
  borderBottom: 'none',
  borderRadius: theme.spacing(1),
  width: '50%',
  minHeight: 39,
  color: '#fff',
  fontSize: 14,
  fontWeight: 400,
  padding: theme.spacing(1, 2),
  cursor: 'pointer',
  transition: 'border-color 0.3s, color 0.3s',
  ...(isActive && {
    borderColor: activeColor || '#6b63ff',
  }),
  '&:hover': {
    borderColor: hoverColor || '#50fa7b',
  },
  '&:focus': {
    outline: 'none',
  },
}));