import { vec3 } from "gl-matrix";
import { BoneInfo, PrimitiveData, SkinData } from "./Mesh";
import { Primitive } from "./Primitive";

const findBottomBone = (boneInfo: BoneInfo[], height: number): BoneInfo | undefined => {
  return boneInfo.find(b => b.height <= height);
}

const findTopBone = (boneInfo: BoneInfo[], height: number): BoneInfo | undefined => {
  return boneInfo.find(b => b.height > height);
}


function createPrimitiveData(geometry: CylinderGeometry, skinData: SkinData): PrimitiveData {
  const { height, radius, radialSegments, heightSegments } = geometry;
  const { bones } = skinData;

  // const halfHeight = height / 2;
  const segmentAngle = (2 * Math.PI) / radialSegments;
  const segmentHeight = height / heightSegments;


  const boneInfo: BoneInfo[] = Array.from({ length: bones }, (_, i) => ({
    bone: i,
    height: i / (bones - 1) * height
  }));

  const reverseBoneInfo = boneInfo.slice().reverse();


  const vertices: vec3[] = [];
  const normals: vec3[] = [];
  const uvs: number[] = [];
  const indices: number[] = [];
  const skinWeights: number[] = [];
  const skinIndices: number[] = [];


  for (let y = 0; y <= heightSegments; y++) {
    const yPosition = y * segmentHeight;

    for (let i = 0; i <= radialSegments; i++) {
      const angle = i * segmentAngle;
      const xPosition = radius * Math.cos(angle);
      const zPosition = radius * Math.sin(angle);

      vertices.push([xPosition, yPosition, zPosition]);
      normals.push([xPosition, 0, zPosition]);
      uvs.push(i / radialSegments, 1 - y / heightSegments);


      // Attach to bone 
      const bottomBone = findBottomBone(reverseBoneInfo, yPosition);
      const topBone = findTopBone(boneInfo, yPosition);

      if(bottomBone && topBone) {
        const boneOffset = topBone.height - bottomBone.height;
        const bottomBoneWeight = 1 - (yPosition - bottomBone.height) / boneOffset;
        const topBoneWeight = 1 - bottomBoneWeight;

        skinWeights.push(bottomBoneWeight, topBoneWeight, 0, 0);
        skinIndices.push(bottomBone.bone, topBone.bone, 0, 0);
      } else if(bottomBone) {
        skinWeights.push(1, 0, 0, 0);
        skinIndices.push(bottomBone.bone, 0, 0, 0);
      } else if(topBone) {
        skinWeights.push(0, 1, 0, 0);
        skinIndices.push(topBone.bone, 0, 0, 0);
      } else {
        skinWeights.push(1, 0, 0, 0);
        skinIndices.push(0, 0, 0, 0);
      }
      //const weight1 = 1 - y / heightSegments;
      //const weight2 = y / heightSegments;
      //skinWeights.push(weight1, weight2, 0, 0);
      //skinIndices.push(0, 1, 0, 0);
    }
  }

  // Create indices
  for (let y = 0; y < heightSegments; y++) {
    for (let x = 0; x < radialSegments; x++) {
      const a = y * (radialSegments + 1) + x;
      const b = a + radialSegments + 1;

      indices.push(a, b, a + 1);
      indices.push(b, b + 1, a + 1);
    }
  }

  return {
    vertices,
    normals: normals.map(n => vec3.normalize(n, n)),
    uvs,
    indices,
    skinWeights,
    skinIndices
  };
}

export type CylinderGeometry = {
  height: number;
  radius: number;
  radialSegments: number;
  heightSegments: number;
};

export class CylinderPrimitive extends Primitive {
  constructor(
    public geometry: CylinderGeometry,
    public skinData: SkinData
  ) {
    super(createPrimitiveData(geometry, skinData));
  }
}