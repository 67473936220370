import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccount } from '../components/providers/AccountProvider';
import { Box, Divider, Typography, styled } from '@mui/material';
import BlurPaper from '../components/dumps/BlutPaper';
import BigCoin from '../components/dumps/coins/BigCoin';
import SmallCoin from '../components/dumps/coins/SmallCoin';
import { TextField as TextFieldMui } from '@mui/material';
import GoldButton from '../components/dumps/GoldButton';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  background: 'linear-gradient(180deg, #2C2F35 0%, #352761 100%)',
}));

const TextField = styled(TextFieldMui)(({ theme }) => ({
  marginBottom: '1rem',
}));

const SigInPage: React.FC = () => {
  const { user } = useAccount();
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate('/gallery');
    }
  }, [user]);

  const { signUp } = useAccount();
  const [name, setName] = useState('');

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  return (
    <Root>
      <BigCoin sx={{
        position: 'absolute',
        right: -30,
        top: '30%',
        width: 200, height: 200,
      }}/>
      <BlurPaper
        sx={{
          width: '90%',
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Typography variant='h6' textAlign='center'>
            You can change your name now, or do it later in the application settings
          </Typography>
          <Divider />
          <TextField
            label="Enter your name"
            variant="standard"
            value={name}
            onChange={handleNameChange}
          />
        </Box>
      </BlurPaper>
      <SmallCoin
        duration='0.45s'
        sx={{
          position: 'absolute',
          left: -30,
          top: '50%',
          width: 180, height: 180,
        }}
      />
      <GoldButton
        sx={{
          position: 'absolute',
          width: '90%',
          bottom: 20,
          color: 'white',
        }}
        onClick={() => signUp(name)}
        disabled={name.length < 3}
        fullWidth
      >
        Apply
      </GoldButton>
    </Root>
  );
};

export default SigInPage;