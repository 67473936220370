import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import { CircularProgress, CircularProgressProps, Typography } from '@mui/material';
import MobilePage from '../components/layout/MobilePage';
import { useNavigate } from 'react-router-dom';
import ShowEffect from '../components/dumps/ShowEffect';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100vh',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'linear-gradient(180deg, #2C2F35 0%, #352761 100%)',
  gap: theme.spacing(2),
}));

type GradientCircularProgressProps = CircularProgressProps;
function GradientCircularProgress({ sx, ...props }: GradientCircularProgressProps) {
  return (
    <>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#E09424" />
            <stop offset="100%" stopColor="#7168FF" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress size={60} sx={{
        'svg circle': { stroke: 'url(#my_gradient)' },
        ...sx }}
        {...props}
      />
    </>
  );
}

const AdvImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
}));

const AppIcon = styled('img')(({ theme }) => ({
  width: 150,
  height: 150,
  borderRadius: 20,
}));

const Home: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/game');
    }, 2000);

    return () => clearTimeout(timer);
  }, [navigate]);
  return (
    <MobilePage title='Balloons' hideBottomNav fullSize>
      <Root>
        <ShowEffect>
          <AppIcon src='/logo256.webp' />
        </ShowEffect>
        <ShowEffect duration='0.25s'>
          <Typography variant='h5'>
            Welcome to KickAndDig!
          </Typography>
        </ShowEffect>
        <GradientCircularProgress sx={{ marginTop: 4 }} />
      </Root>
    </MobilePage>
  );
};

export default Home;