import React, { useEffect, useMemo, useState } from 'react';
import { usePlanes } from '../providers/PlanesProvider';
import { Grid, GridProps, lighten, styled } from '@mui/material';
import { Balloon, Plane } from '../../types/plane';
import Link from '@mui/material/Link';
import { generatePlaneBlop } from '../../lib/planeGen'
import { useInView } from "react-intersection-observer";

const Card = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  // squre aspect ratio
  overflow: 'hidden',
}));

interface CardItemProps {
  plane: Plane;
  onClick?: (balloon: Balloon) => void;
}

const CardItem: React.FC<CardItemProps> = ({ plane, onClick }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const [url, setUrl] = useState<string | null>(null);
  useEffect(() => {
    inView && generatePlaneBlop(plane)
      .then(blob => {
        const url = URL.createObjectURL(blob);
        setUrl(url);

        return () => URL.revokeObjectURL(url);
      })
      .catch(e => console.log(e));
  }, [inView, plane])

  // plane to strin
  const token = useMemo(() => encodeURI(JSON.stringify(plane)), [plane]);
  return onClick ? (
    <Card ref={ref} onClick={() => onClick(plane)} >
        {/* @ts-ignore */}
        <model-viewer
          src={url}
          shadow-intensity="1"
          auto-rotate
          autoplay
          alt={`A 3D model of a plane ${plane.id}`}
          style={{ width: '100%', height: '255px', display: 'block' }}
        />
      </Card>
  ) : (
    <Link href={`/game?plane=${token}`}>
      <Card ref={ref} >
        {/* @ts-ignore */}
        <model-viewer
          src={url}
          shadow-intensity="1"
          auto-rotate
          autoplay
          alt={`A 3D model of a plane ${plane.id}`}
          style={{ width: '100%', height: '255px', display: 'block' }}
        />
      </Card>
    </Link>
  );

  // <CardImage src={invalidPreviewUrl} alt="Plane preview" />
}

type PlaneGridProps = Omit<GridProps, 'children' | 'onClick'> & {
  onClick?: (balloon: Balloon) => void;
};

const PlaneGrid: React.FC<PlaneGridProps> = ({ onClick, ...props }) => {
  const { planes } = usePlanes();

  return (
    <Grid container spacing={1} {...props}>
      {planes.map((f, index) => (
        <Grid item key={f.id} xs={12} sm={4} md={3} lg={2}>
          <CardItem plane={f} onClick={onClick} />
        </Grid>
      ))}
    </Grid>
  );
};

export default PlaneGrid;