import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, BoxProps, styled } from '@mui/material';
import { useBalloonModel } from '../providers/BalloonModelProvider';
import useElementSize from '../../lib/use-element-size';
import { mix } from '../../lib/utils';
import chroma from 'chroma-js';
import { bounceAnimationCss } from '../../lib/animations';

const Root = styled(Box)({
  position: 'relative',
  overflow: 'hidden',
});

// Props
const shakeAnimationName = 'shake';
const squeezeAnimationName = 'squeeze';
const timeToShake = 5000;

const gamePlay = {
  tensionGrowNormal: 0.015,
  tensionGrowLow: 0.004,
  tensionDecayPerSecond: 0.02,
}


//  convert tension [0-1] to render tension [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
const tensionToRenderTension = (tension: number) => {
  const tensionStep = 0.1;
  return Math.floor(tension / tensionStep) * tensionStep;
}

// Visuals
// rgba(0, 255, 0, 0.25))
// const lowColor = '#FFC0CB';
const lowColor = '#ff69b4';
// const heightColor = '#FF69B4';
const heightColor = '#ff1493';
// const arrowColor = '%23FF69B4';
const arrowColor = '%23ff1493';

// with color arrowColor
const svgArrowUp = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="${arrowColor}" d="M12 21L3 12h18z"/></svg>')`;
const svgArrowDown = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="${arrowColor}" d="M12 3l9 9H3z"/></svg>')`;

const View3d = styled('div')<{ 
  direction?: 'down' | 'up',
  tension?: number,
}>(({ theme, direction, tension = 0 }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  position: 'relative',
  background: `linear-gradient(to ${direction === 'up' ? 'top' : 'bottom'}, ${chroma.mix('#FFFFFF00', heightColor, tension).hex()}, ${chroma.mix(lowColor, heightColor, tension).hex()})`,
  pointerEvents: 'none',
  overflow: 'hidden',
}));

/*
const Bacground = styled('div')<{
  direction?: 'down' | 'up',
}>(({ theme, direction }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  opacity: 0.25,
  backgroundImage: direction === 'down' ?  svgArrowUp : svgArrowDown,
  backgroundSize: '100px 100px',
  backgroundRepeat: 'repeat-x repeat-y',
  maskImage: `linear-gradient(to ${direction === 'up' ? 'bottom' : 'top'}, rgba(0,0,0,1), rgba(0,0,0,0))`,
  pointerEvents: 'none',
}));
*/

const BacgroundUp = styled('div')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  opacity: 0.25,
  backgroundImage: svgArrowUp,
  backgroundSize: '100px 100px',
  backgroundRepeat: 'repeat-x repeat-y',
  maskImage: `linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))`,
  pointerEvents: 'none',
});

const BacgroundDown = styled('div')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  opacity: 0.25,
  backgroundImage: svgArrowDown,
  backgroundSize: '100px 100px',
  backgroundRepeat: 'repeat-x repeat-y',
  maskImage: `linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0))`,
  pointerEvents: 'none',
});


const TouchArea = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
}));

// ModelView with forwardRef
const ModelView = React.memo(React.forwardRef<HTMLDivElement>(({ }, ref) => {
  const { url } = useBalloonModel();

  // @ts-ignore
  return url ? (<model-viewer
      ref={ref}
      src={url}
      shadow-intensity="1"
      animation-name={squeezeAnimationName}
      alt={`A 3D model of a plane`}
      style={{
        width: '100%', height: '80%',
        display: 'block',
        userSelect: 'none', pointerEvents: 'none',
        ...bounceAnimationCss('0.15s'),
      }}
    />
  ) : null;
}));


const num = 9999;
// The preventCollapse function should be called when the scrollable content scrollTop is not equal to zero. 
function preventCollapse() {
  document.documentElement.style.marginTop = num + "px";
  document.documentElement.style.height = window.innerHeight + num + "px";
  document.documentElement.style.overflow = "hidden";
  window.scrollTo(0, num);
}

function allowCollapse() {
  document.documentElement.style.marginTop = "auto";
  document.documentElement.style.height = "auto";
  document.documentElement.style.overflow = "auto";
}

export type BalloonActionProps = BoxProps & {
  onSuccess?: () => void;
  onBonus?: () => void;
};

const BalloonAction: React.FC<BalloonActionProps> = ({ onSuccess, onBonus, ...props }) => {
  const stageRef = useRef('down')
  const [stage, setStage] = useState<'down' | 'up'>('down');
  const [tension, setTension] = useState(0);
  const [tensionGrow, setTensionGrow] = useState(gamePlay.tensionGrowNormal);
  // action time
  const actionTimeRef = useRef(Date.now());

  // Sounds
  const audioRef1 = useRef<HTMLAudioElement>(null);
  const audioRef2 = useRef<HTMLAudioElement>(null);
  const audioRef1_1 = useRef<HTMLAudioElement>(null);
  const audioRef2_1 = useRef<HTMLAudioElement>(null);
  const audioBonusRef = useRef<HTMLAudioElement>(null);

  // Update animation
  const viewRef = useRef<HTMLDivElement>(null);
  const [setRef, size] = useElementSize();
  const updateAnimation = useCallback((y: number) => {
    const startAnimation = 0.1;
    const endAnimation = 1.1;

    // percent to height
    const actionRect = { y: 0.05, height: 0.9 }
    const offset = actionRect.y * size.height;
    const height = actionRect.height * size.height
    const t = (y - offset) / height;
    const newAnimationTime = mix(startAnimation, endAnimation, t);

    // @ts-ignore
    viewRef.current.currentTime = newAnimationTime;

    // Update game state
    if(stageRef.current === 'down' && t > 0.55) {
      stageRef.current = 'up';
      setStage('up');
      setTension(old => Math.min(1, old + tensionGrow));
      
      // Play sound
      if(audioRef1.current?.paused) {
        audioRef1.current?.play();
      } else {
        audioRef1_1.current?.play();
      }

      // Update action time
      actionTimeRef.current = Date.now();
      
    } else if(stageRef.current === 'up' && t < 0.35) {
      stageRef.current = 'down';
      setStage('down');
      setTension(old => Math.min(1, old + tensionGrow));

      // Play sound
      if(audioRef2.current?.paused) {
        audioRef2.current?.play();
      } else {
        audioRef2_1.current?.play();
      }

      // Success
      onSuccess && onSuccess();

      // Update action time
      actionTimeRef.current = Date.now();
    }
  }, [onSuccess, size, tensionGrow]);

  // Tension decay every second
  useEffect(() => {
    const interval = setInterval(() => {
      setTension(old => Math.max(0, old - gamePlay.tensionDecayPerSecond));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // Bonus
  const bBonus = tension >= 1;
  useEffect(() => {
    if(bBonus) {
      onBonus && onBonus();
      setTensionGrow(old => Math.max(gamePlay.tensionGrowLow, old * 0.9));
      setTension(0);
      audioBonusRef.current?.play();
    }
  }, [onBonus, bBonus]);

  const reset = useCallback(() => {
    // @ts-ignore
    viewRef.current.currentTime = 0;
  }, []);

  
  const touchAreaRef = useRef<HTMLDivElement>(null);
  const handleMouse = useCallback((e: MouseEvent) => {
    e.preventDefault();
    if (e.buttons === 1) updateAnimation(e.clientY);
  }, [updateAnimation]);

  const handleStart = useCallback((e: TouchEvent) => {
    preventCollapse();
    e.preventDefault();
    updateAnimation(e.touches[0].clientY);
  }, [updateAnimation]);

  const handleTouch = useCallback((e: TouchEvent) => {
    if (window.scrollY === 0) {
      window.scrollTo(0, 1);
    }
    e.preventDefault();
    updateAnimation(e.touches[0].clientY);
  }, [updateAnimation]);

  const handleTouchReset = useCallback((e: TouchEvent) => {
    allowCollapse();
    // only end first touch
    if(e.touches.length === 0) {
      reset();
    }
  }, [reset]);

  /*
  const handleMouse = useCallback((e: MouseEvent) => {
    isIOS && e.preventDefault();
    if (e.buttons === 1) updateAnimation(e.clientY);
  }, [updateAnimation]);
  const handleTouch = useCallback((e: TouchEvent) => {
    isIOS && e.preventDefault();
    updateAnimation(e.touches[0].clientY);
  }, [updateAnimation]);
  const handleTouchReset = useCallback((e: TouchEvent) => {
    // only end first touch
    if(e.touches.length === 0) {
      reset();
    }
  }, [reset]);
  */

  // touchAreaRef passive false
  useEffect(() => {
    const el = touchAreaRef.current;
    el?.addEventListener('touchstart', handleTouch, { passive: false });
    el?.addEventListener('touchmove', handleTouch, { passive: false });
    el?.addEventListener('touchend', handleTouchReset, { passive: false });
    el?.addEventListener('mousedown', handleMouse, { passive: false });
    el?.addEventListener('mousemove', handleMouse, { passive: false });
    el?.addEventListener('mouseup', reset, { passive: false });

    return () => {
      el?.removeEventListener('touchstart', handleTouch);
      el?.removeEventListener('touchmove', handleTouch);
      el?.removeEventListener('touchend', handleTouchReset);
      el?.removeEventListener('mousedown', handleMouse);
      el?.removeEventListener('mousemove', handleMouse);
      el?.removeEventListener('mouseup', reset);
    };
  }, [handleTouch, handleMouse, reset, handleTouchReset]);
  

  
  // Play shake animation if nothing is happening more then 10 seconds
  useEffect(() => {
    const interval1 = setInterval(() => {
      if(viewRef.current && Date.now() - actionTimeRef.current > timeToShake) {
        // @ts-ignore
        viewRef.current.animationName = shakeAnimationName;
        // @ts-ignore
        viewRef.current.currentTime = 0;
        // @ts-ignore
        viewRef.current.play({repetitions: 1});
      }
    }, 2000);

    const interval2 = setInterval(() => {
      // @ts-ignore
      if(viewRef.current && viewRef.current.animationName === shakeAnimationName) {
        // @ts-ignore
        viewRef.current.animationName = squeezeAnimationName;
        // @ts-ignore
        viewRef.current.currentTime = 0;
        // @ts-ignore
        viewRef.current.pause();
      }
    }, 3000);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
    }
  }, []);
  
/*
  useEffect(() => {
    document.addEventListener('touchstart', handleTouch, { passive: false });
    document.addEventListener('touchmove', handleTouch, { passive: false });
    document.addEventListener('touchend', handleTouchReset, { passive: false });
    document.addEventListener('mousedown', handleMouse, { passive: false });
    document.addEventListener('mousemove', handleMouse, { passive: false });
    document.addEventListener('mouseup', reset, { passive: false });

    return () => {
      document.removeEventListener('touchstart', handleTouch);
      document.removeEventListener('touchmove', handleTouch);
      document.removeEventListener('touchend', handleTouchReset);
      document.removeEventListener('mousedown', handleMouse);
      document.removeEventListener('mousemove', handleMouse);
      document.removeEventListener('mouseup', reset);
    };
  }, [handleTouch, handleMouse, reset, handleTouchReset]);
  */

  // optimize css generation
  const viewTenstion = tensionToRenderTension(Math.min(1, tension * (1/0.8)));
  return (
    <Root {...props}>
      <BacgroundUp style={{ display: stage === 'up' ? 'block' : 'none' }} />
      <BacgroundDown style={{ display: stage === 'down' ? 'block' : 'none' }} />
      <View3d ref={setRef} direction={stage} tension={viewTenstion}>
        <ModelView ref={viewRef} />
      </View3d>
      <TouchArea ref={touchAreaRef} />
      <audio ref={audioRef1} src="/sounds/ui_lock.wav" />
      <audio ref={audioRef1_1} src="/sounds/ui_lock.wav" />
      <audio ref={audioRef2} src="/sounds/action.wav" />
      <audio ref={audioRef2_1} src="/sounds/action.wav" />
      <audio ref={audioBonusRef} src="/sounds/duck-quack.mp3" />
    </Root>
  );
}

export default React.memo(BalloonAction);