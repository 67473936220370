import React from 'react';
import MobilePage from '../components/layout/MobilePage';
import AccountName from '../components/containers/account/AccountName';
import { Tab, TabsContainer, TabsContainerProps } from '../components/dumps/Tabs';
import AccountScore from '../components/containers/account/AccountScore';
import { InfoBox, InfoBoxContent, InfoBoxHeader, InfoBoxesContainer, InfoBoxesContainerProps } from '../components/dumps/InfoBox';
import { useNextLevelXp } from '../components/providers/AccountStateProvider';
import UpgradeGrid from '../components/containers/UpgradeGrid';
import { bounceAnimationCss } from '../lib/animations';

type InfoBoxProps = InfoBoxesContainerProps;
const InfoBoxes: React.FC<InfoBoxProps> = (props: InfoBoxProps) => {
  const { left } = useNextLevelXp();
  return (
    <InfoBoxesContainer {...props}>
      <InfoBox>
        <InfoBoxHeader color="#686DFF">Coins to level up</InfoBoxHeader>
        <InfoBoxContent>{left}</InfoBoxContent>
      </InfoBox>
      <InfoBox>
        <InfoBoxHeader color="#32CD32">Profit /sec</InfoBoxHeader>
        <InfoBoxContent>
          <span role="img" aria-label="coin">💰</span> +0,0K
        </InfoBoxContent>
      </InfoBox>
    </InfoBoxesContainer>
  );
};

type TabType = 'upgrades' | 'visuals';
const defaultTab: TabType = 'upgrades';

type TabsProps = TabsContainerProps & {
  activeTab: TabType;
  setActiveTab: (tab: TabType) => void;
};
const Tabs: React.FC<TabsProps> = ({ activeTab, setActiveTab, ...props }) => {
  return (
    <TabsContainer {...props}>
      <Tab
        activeColor='#FE881C'
        hoverColor='#FE881C'
        isActive={activeTab === 'upgrades'}
        onClick={() => setActiveTab('upgrades')}
      >
        Upgrades
      </Tab>
      <Tab
        activeColor='#FE881C'
        hoverColor='#FE881C'
        isActive={activeTab === 'visuals'}
        onClick={() => setActiveTab('visuals')}
      >
        Visuals
      </Tab>
    </TabsContainer>
  );
};

const Home: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState<TabType>(defaultTab);
  return (
    <MobilePage title='Store'>
      <AccountName sx={{ marginY: 2, ...bounceAnimationCss('0.15s') }} />
      <InfoBoxes sx={{ ...bounceAnimationCss('0.2s') }} />
      <AccountScore sx={{ marginY: 2, ...bounceAnimationCss('0.25s') }} />
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} sx={{ ...bounceAnimationCss('0.3s') }} />
      <UpgradeGrid />
    </MobilePage>
  );
};

export default Home;