import React, { createContext, useEffect, useState } from 'react';
import { useBalloon } from './AccountBallonProvider';
import { generatePlaneBlop } from '../../lib/planeGen';

type BalloonModelContextType = {
  url?: string;
}

const BalloonModelContext = createContext<BalloonModelContextType>({

});

export type BalloonModelProviderProps = {
  children: React.ReactNode;
};


export const BalloonModelProvider: React.FC<BalloonModelProviderProps> = ({ children }) => {
  const { balloon } = useBalloon();
  const [url, setUrl] = useState<string | undefined>();
  useEffect(() => {
    balloon && generatePlaneBlop(balloon)
      .then(blob => {
        const url = URL.createObjectURL(blob);
        setUrl(url);

        return () => URL.revokeObjectURL(url);
      })
      .catch(e => console.log(e));
  }, [balloon])
  
  return (
    <BalloonModelContext.Provider value={{
      url,
    }}>
      {children}
    </BalloonModelContext.Provider>
  );
}

export const useBalloonModel = () => {
  return React.useContext(BalloonModelContext);
};
