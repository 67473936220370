import { Button, ButtonProps, Typography, styled } from '@mui/material';
import MineIcon from './icons/MineIcon';

const Label = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: '400',
  background: 'linear-gradient(90deg, #FFB800 0%, #FF6B00 100%)',
  WebkitBackgroundClip: 'text',
  MozBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  MozTextFillColor: 'transparent',
 

}));

export type HelpButtonProps = ButtonProps;
function HelpButton({ ...props }: HelpButtonProps) {
  return (
    <Button
      startIcon={<MineIcon htmlColor='#FFB800'/>}
      sx={{ textTransform: 'none' }} {...props} >
      <Label>
        Help a friend
      </Label>
    </Button>
  )
}

export default HelpButton;