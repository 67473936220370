import React from 'react';
import MobilePage from '../components/layout/MobilePage';

import { BalloonGuard } from '../components/providers/AccountBallonProvider';
import GameViewer from '../components/widgets/GameViewer';
// import dynamic from 'next/dynamic';
// const GameViewer = dynamic(() => import('../components/widgets/GameViewer'), { ssr: false });

const GamePage: React.FC = () => {
  return (
    <BalloonGuard>
      <MobilePage title="Game" fullSize>
        <GameViewer />
      </MobilePage>
    </BalloonGuard>
  );
};

export default GamePage;