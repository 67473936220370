import React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { useAccount } from '../../providers/AccountProvider';

export type AccountNameProps = Omit<TypographyProps, 'children'>;

const AccountName: React.FC<AccountNameProps> = (props: AccountNameProps) => {
  const { user } = useAccount();

  return (
    <Typography {...props}>
      {user?.name || '...'}
    </Typography>
  );
};

export default AccountName;