import React from 'react';
import { styled } from '@mui/system';
import { Avatar, Button, Paper } from '@mui/material';
import MobilePage from '../components/layout/MobilePage';
import NextLink from '@mui/material/Link';
import { BalloonGuard, useBalloon } from '../components/providers/AccountBallonProvider';
import AccountResetButton from '../components/containers/AccountResetButton';
import AccountName from '../components/containers/account/AccountName';
import SmallCoin from '../components/dumps/coins/SmallCoin';
import BigCoin from '../components/dumps/coins/BigCoin';
import BlurPaper from '../components/dumps/BlutPaper';
import BounceButton from '../components/dumps/BounceButton';

const AccountPaper = BlurPaper;

const Link = styled(NextLink)(({ theme }) => ({
  width: '100%',
}));

const Root = styled(Paper)(({ theme }) => ({
  width: '100%',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
}));

const Actions = () => {
  const { hashId } = useBalloon();
  return (
    <>
      <Link href={`https://polygon.kickandig.com/offline?plane=${hashId}`}>
        <BounceButton
          variant='contained'
          color='secondary'
          size='large'
          fullWidth
        >
          Train
        </BounceButton>
      </Link>
      <Link href={`https://polygon.kickandig.com?plane=${hashId}`}>
        <BounceButton
          variant='contained'
          color='secondary'
          size='large'
          fullWidth
        >
          To the Battle
        </BounceButton>
      </Link>
      <Link href='/gallery'>
        <BounceButton
          variant='contained'
          color='primary'
          size='large'
          fullWidth
          sx={{ marginTop: 2 }}
        >
          Select New Balloon
        </BounceButton>
      </Link>
    </>
  )
}

const AdditionalPage: React.FC = () => {
  return (
    <BalloonGuard>
      <MobilePage title='Balloons'>
        <BigCoin sx={{
          position: 'absolute',
          right: -30,
          top: '10%',
          width: 200,
          height: 200,
        }}/>
        <AccountPaper duration='0.25s'>
          <Avatar src='/logo128.webp' sx={{ width: 100, height: 100 }} />
          <AccountName variant='h6' />
          <AccountResetButton color='error' size='large' variant='outlined' fullWidth>
            Reset Account
          </AccountResetButton>
        </AccountPaper>
        <Root elevation={0}>
          <Actions />
        </Root>
        <SmallCoin sx={{
          position: 'absolute',
          left: 0,
          top: '5%',
          width: 120,
          height: 120,
        }}/>
      </MobilePage>
    </BalloonGuard>
  );
};

export default AdditionalPage;