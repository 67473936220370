import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/system';
import { bounceAnimationCss } from '../../lib/animations';

export type GoldButtonProps = ButtonProps & {
  duration?: string;
};

const GoldButton = styled(Button)<{ duration?: string }>(({ theme, duration = '0.15s' }) => ({
  background: 'linear-gradient(90deg, #FFB800 0%, #FF6B00 100%)',
  minHeight: '56px',
  fontSize: '16px',
  fontWeight: 600,
  '&:hover': {
    background: 'linear-gradient(90deg, #FFB800 0%, #FF6B00 100%)',
  },
  ...bounceAnimationCss(duration)
}));

export default GoldButton;