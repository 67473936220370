import React, { useCallback } from 'react';
import RegenButton from '../components/containers/RegenButton';
import PlaneGrid from '../components/containers/PlaneGrid';
import MobilePage from '../components/layout/MobilePage';
import { PlanesProvider } from '../components/providers/PlanesProvider';
import { useBalloon } from '../components/providers/AccountBallonProvider';
import { Balloon } from '../types/plane';
import { useNavigate } from 'react-router-dom';
import { useAccount } from '../components/providers/AccountProvider';

const GalleryPage: React.FC = () => {
  const { user } = useAccount();
  const { setBalloon } = useBalloon();
  const navigate = useNavigate();
  const handleSelected = useCallback((balloon: Balloon) => {
    setBalloon(balloon);
    // replace with '/game' to navigate to the game page
    navigate('/game');
  }, [navigate, setBalloon]);
  return (
    <PlanesProvider initAmount={100} disabled={!user}>
      <MobilePage title='Balloons' hideBottomNav>
        <PlaneGrid onClick={handleSelected} sx={{ overflow: 'auto', marginBottom: 2 }} />
        <RegenButton sx={{
          position: 'absolute',
          width: '90%',
          bottom: 20,
        }}/>
      </MobilePage>
    </PlanesProvider>
  );
};

export default GalleryPage;