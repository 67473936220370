export type Color = [number, number, number, number];
import hexRgb from 'hex-rgb';

export const colors: string[] = [
  "#FFE4C4", // Body color
  "#FFD700", // Gold
  "#FF0000", // Red
  "#008000", // Green
  "#0000FF", // Blue
  "#FFA500", // Orange
  "#FFFF00", // Yellow
  "#800080", // Purple
  "#FFC0CB", // Pink
  "#A52A2A", // Brown
  "#00FFFF", // Cyan
  "#808080", // Gray
  "#000000", // Black
  "#FFFFFF", // White
  "#C0C0C0", // Silver
  "#FF6347", // Tomato
  "#40E0D0", // Turquoise
  "#7FFFD4", // Aquamarine
  "#4682B4", // Steel Blue
  "#D2691E", // Chocolate
  "#B8860B", // Dark Goldenrod
  "#8B4513", // Saddle Brown
  "#228B22", // Forest Green
  "#20B2AA", // Light Sea Green
  "#FF69B4", // Hot Pink
  "#CD5C5C", // Indian Red
  "#4B0082", // Indigo
  "#F0E68C", // Khaki
  "#E6E6FA", // Lavender
  "#7CFC00"  // Lawn Green
];

export function getRandomColorHex(): string {
  return colors[Math.floor(Math.random() * colors.length)];
}

export function hex2Array(hex: string): Color {
  const elements =  hexRgb(hex, { format: 'array' })
  return [elements[0] / 255, elements[1] / 255, elements[2] / 255, 1];
}

export function getRandomColor(): Color {
  const hexColor = getRandomColorHex();
  return hex2Array(hexColor)
}