import { Accessor, Document, Primitive, Buffer } from '@gltf-transform/core';
import { Material } from './types';
import { Mesh } from './primitives/Mesh';
import { Transform } from './common';

export class DocGenerator {
  doc: Document;
  buffer: Buffer;
  constructor() {
    this.doc = new Document();
    this.buffer = this.doc.createBuffer();
  }

    
  createPositionAccessor(vertices: number[]) {
    const position = this.doc.createAccessor()
      .setType(Accessor.Type.VEC3)
      .setArray(new Float32Array(vertices));
    return position;
  }

  createNormalAccessor(normals: number[]) {
    const normal = this.doc.createAccessor()
      .setType(Accessor.Type.VEC3)
      .setArray(new Float32Array(normals));
    return normal;
  }

  createSkinWeightAccessor(skinWeights: number[]) {
    const skinWeight = this.doc.createAccessor()
      .setType(Accessor.Type.VEC4)
      .setArray(new Float32Array(skinWeights));
    return skinWeight;
  }

  createSkinIndexAccessor(skinIndices: number[]) {
    const skinIndex = this.doc.createAccessor()
      .setType(Accessor.Type.VEC4)
      .setArray(new Uint16Array(skinIndices));
    return skinIndex;
  }

  createIndicesAccessor(indices: number[]) {
    const indicesAccessor = this.doc.createAccessor()
      .setType(Accessor.Type.SCALAR)
      .setArray(new Uint16Array(indices));
    return indicesAccessor;
  }

  createPrimitive(meshData: Mesh, mData: Material, name: string) {
    const position = this.createPositionAccessor(meshData.vertices).setBuffer(this.buffer);
    const normal = this.createNormalAccessor(meshData.normals).setBuffer(this.buffer);
    const indices = this.createIndicesAccessor(meshData.triangles).setBuffer(this.buffer);

    const material = this.doc.createMaterial()
      .setBaseColorFactor(mData.color)
      .setMetallicFactor(mData.metallic)
      .setRoughnessFactor(mData.roughness);

    const prim = this.doc.createPrimitive().setName(name);
    prim.setMode(Primitive.Mode.TRIANGLES)
      .setMaterial(material)
      .setAttribute('POSITION', position)
      .setAttribute('NORMAL', normal)
      .setIndices(indices);
    
      return prim;
  }

  createSkinPrimitive(meshData: Mesh, mData: Material, name: string = 'BalloonPrimitive') {
    // const buffer = this.doc.createBuffer();
    const position = this.createPositionAccessor(meshData.vertices).setBuffer(this.buffer);
    const normal = this.createNormalAccessor(meshData.normals).setBuffer(this.buffer);
    const skinWeight = this.createSkinWeightAccessor(meshData.skinWeights).setBuffer(this.buffer);
    const skinIndex = this.createSkinIndexAccessor(meshData.skinIndices).setBuffer(this.buffer);
    const indices = this.createIndicesAccessor(meshData.triangles).setBuffer(this.buffer);

    const material = this.doc.createMaterial()
      .setBaseColorFactor(mData.color)
      .setMetallicFactor(mData.metallic)
      .setRoughnessFactor(mData.roughness);

    const prim = this.doc.createPrimitive().setName(name);
    prim.setMode(Primitive.Mode.TRIANGLES)
      .setMaterial(material)
      .setAttribute('POSITION', position)
      .setAttribute('NORMAL', normal)
      .setAttribute('WEIGHTS_0', skinWeight)
      .setAttribute('JOINTS_0', skinIndex)
      .setIndices(indices);

    return prim;
  }

    
  public copyFrom(sourceDoc: Document, transform: Transform, mData?: Material) {
    const targetDoc = this.doc;
    const sourceRoot = sourceDoc.getRoot();
    const targetRoot = targetDoc.getRoot();

    const sourceNode = sourceRoot.listNodes()[0]
    
    // Сreate a new node in the target document.
    const targetNode = targetDoc.createNode()
      .setTranslation(transform.position)
      .setRotation(transform.rotation)
      .setScale(transform.scale);

    // Copy the mesh (if any).
    const sourceMesh = sourceNode.getMesh();
    if (sourceMesh) {
      const targetMesh = targetDoc.createMesh();
      targetMesh.setName(sourceMesh.getName());
      targetNode.setMesh(targetMesh);

      sourceMesh.listPrimitives().forEach(sourcePrim => {
        // Clone primitivies.
        const targetPrim = targetDoc.createPrimitive().setName(sourcePrim.getName());
        targetPrim.setMode(sourcePrim.getMode())

        // Clone material.
        const sourceMaterial = sourcePrim.getMaterial()
        if(sourceMaterial) {
          const material = targetDoc.createMaterial()
            .setBaseColorFactor(mData ? mData.color : sourceMaterial.getBaseColorFactor())
            .setMetallicFactor(sourceMaterial.getMetallicFactor())
            .setRoughnessFactor(sourceMaterial.getRoughnessFactor());
          targetPrim.setMaterial(material);
        }

        // Clone attributes.
        sourcePrim.listSemantics().forEach(semantic => {
          const sourceAccessor = sourcePrim.getAttribute(semantic) as Accessor;
          const targetAccessor =  targetDoc.createAccessor()
            .setType(sourceAccessor.getType())
            .setArray(sourceAccessor.getArray());

          targetPrim.setAttribute(semantic, targetAccessor);
        });

        // Clone indices.
        const sourceIndices = sourcePrim.getIndices() as Accessor;
        const indices = targetDoc.createAccessor()
          .setType(sourceIndices.getType())
          .setArray(sourceIndices.getArray());
          targetPrim.setIndices(indices);

        targetMesh.addPrimitive(targetPrim);
      });
    }

    // Copy matrix.
    // targetNode.setMatrix(sourceNode.getMatrix());

    /*
    // Copy children.
    await Promise.all(sourceNode.listChildren().map(async (child) => {
      const copiedChild = await copyNode(sourceDoc, targetDoc, child.getName());
      targetNode.addChild(copiedChild);
    }));
    */

    return targetNode;
  }

  toDocument() {
    return this.doc;
  }
}