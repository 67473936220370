import { Box, BoxProps, styled } from '@mui/material';
import React from 'react';
import { bounceAnimationCss } from '../../lib/animations';

const AnimationBox = styled(Box)<{ duration?: string }>(({ theme, duration = '0.15s' }) => ({
  display: 'inline-block',
  ...bounceAnimationCss(duration),
}));


export type ShowEffectProps = BoxProps & {
  children: React.ReactNode;
  duration?: string;
};

export const ShowEffect: React.FC<ShowEffectProps> = ({ children, duration, ...props }) => {
  return (
    <AnimationBox {...props} duration={duration}>
      {children}
    </AnimationBox>
  );
}


export const ShowEffectFast = ShowEffect;

export const ShowEffectNormal: React.FC<ShowEffectProps> = ({ children, ...props }) => {
  return <ShowEffectFast {...props} duration='0.25s'>{children}</ShowEffectFast>;
};

export const ShowEffectSlow: React.FC<ShowEffectProps> = ({ children, ...props }) => {
  return <ShowEffectFast {...props} duration='0.35s'>{children}</ShowEffectFast>;
};


export default ShowEffect;