import React, { useCallback, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import BalloonAction from '../containers/BalloonAction';
import { motion, useAnimation  } from 'framer-motion';
import ScoreText from '../containers/account/AccountScore';
import useVibrate from '../../lib/useVibrate';
import XPProgress from '../containers/XPProgress';
import { useAccountState, useProgressionState } from '../providers/AccountStateProvider';
import AccountLevel from '../containers/account/AccountLevel';
import AccountName from '../containers/account/AccountName';
import { isIOS } from 'react-device-detect';
import { isTMA } from '@tma.js/sdk';
import GoldButton from '../dumps/GoldButton';
import { bounceAnimationCss } from '../../lib/animations';

// Button under PlaneView in the bottom
const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(4),
  width: '100%',
  height: '100%',
  background: theme.palette.background.default,
  overflow: 'hidden',
}));

/*
const flyerAnimation = {
  initial: { y: -50, opacity: 1 },
  animate: { y: -250, opacity: 0 },
  transition: { duration: 1, ease: "easeOut" }
};
*/


type Flyer = { id: number, text: string, bonus?: boolean, active: boolean };
const createFlyer = (text: string, bonus = false): Flyer => ({ id: Date.now(), text, bonus, active: true });

const INIT_BUFFER_SIZE = 5; // Number of reusable elements

const FlyerWrapper = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
});

const HiddenInput = styled('input')({
  display: 'none',
});

const FlyerItem = styled('div')<{ bonus?: boolean }>(({ bonus }) => ({
  '@keyframes flyerAnimation': {
    '0%': {
      transform: 'translateY(-50px)',
      opacity: 1,
    },
    '100%': {
      transform: 'translateY(-300px)',
      opacity: 0,
    },
  },
  animation: 'flyerAnimation 1s ease-out forwards',
  position: 'absolute',
  color: bonus ? '#FF6B00' : 'white',
  fontSize: bonus ? 120 : 70,
  fontWeight: 'bold',
  userSelect: 'none',
  pointerEvents: 'none',
  overflow: 'hidden',
}));

/*
const FlyerItem = styled('label')<{ bonus?: boolean }>(({ bonus }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: bonus ? 'gold' : 'green',
  fontSize: bonus ? 120 : 70,
  fontWeight: 'bold',
  userSelect: 'none',
  pointerEvents: 'none',
  overflow: 'hidden',
  '@keyframes flyerAnimation': {
    '0%': {
      transform: 'translateY(-50px)',
      opacity: 1,
    },
    '100%': {
      transform: 'translateY(-300px)',
      opacity: 0,
    },
  },
  'input[type="checkbox"]:checked + &': {
    animation: 'flyerAnimation 1s ease-out forwards',
  },
}));
*/
const ScoreTitle = styled(ScoreText)({
  paddingTop: 2,
  userSelect: 'none',
  pointerEvents: 'none',
  fontWeight: 'bold',
  color: 'white',
});

/*
const Header = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});
*/

const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  gap: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  // borderRadius: theme.spacing(1.5),
  padding: theme.spacing(1),
  top: 0, //theme.spacing(1),
  userSelect: 'none',
  pointerEvents: 'none',
  overflow: 'hidden',
}));

const Footer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  gap: theme.spacing(2),
  overflow: 'hidden',
}));

const InfoContainerBottom = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: theme.spacing(1),

}));

const LeftContainer = styled(Box)(({ theme }) => ({
  marginRight: 'auto',
  paddingLeft: theme.spacing(2),
  overflow: 'hidden',
}));

const CenterContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  alignItems: 'center',
}));

const FirstInterationWidget = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.5)',
  overflow: 'hidden',
  top: 0,
}));

const RightContainer = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  paddingRight: theme.spacing(2),
  overflow: 'hidden',
}));

const bounceAnimation = {
  initial: { scale: 1, y: 0 },
  animate: { 
    y: [-10, 0], 
    scale: [1.2, 1],
    transition: { yoyo: Infinity, duration: 0.4 } 
  }
};

const bounceAnimationRegular = {
  y: [-10, 0],
  scale: [1.2, 1],
  transition: { yoyo: Infinity, duration: 0.4 }
}

const bounceAnimationBonus = {
  y: [-20, 0],
  scale: [1.5, 1],
  transition: { yoyo: Infinity, duration: 0.4 }
}



const GameViewer: React.FC = () => {
  const { vibrate } = useVibrate();
  // Full Screen
  const fullScreenRef = useRef<HTMLDivElement>(null);
  const enterFullscreen = useCallback(() => {
    const element = fullScreenRef.current;
    // @ts-ignore
    if (element.requestFullscreen) {
      // @ts-ignore
      element.requestFullscreen();
      return true;
      // @ts-ignore
    } else if (element.mozRequestFullScreen) {
      // @ts-ignore
      element.mozRequestFullScreen();
      return true;
      // @ts-ignore
    } else if (element.webkitRequestFullscreen) {
      // @ts-ignore
      element.webkitRequestFullscreen();
      return true;
      // @ts-ignore
    } else if (element.msRequestFullscreen) {
      // @ts-ignore
      element.msRequestFullscreen();
      return true;
    } else {
      return false;
    }
  }, []);

  const exitFullscreen = useCallback(() => {
    // @ts-ignore
    if (document.exitFullscreen) {
      // @ts-ignore
      document.exitFullscreen();
      // @ts-ignore
    } else if (document.mozCancelFullScreen) {
      // @ts-ignore
      document.mozCancelFullScreen();
      // @ts-ignore
    } else if (document.webkitExitFullscreen) {
      // @ts-ignore
      document.webkitExitFullscreen();
      // @ts-ignore
    } else if (document.msExitFullscreen) {
      // @ts-ignore
      document.msExitFullscreen();
    }
  }, []);
  // End Full Screen

  // Play/Stops the game
  const [inFullscreen, setInFullscreen] = React.useState(false);
  const handleFullscreenEnter = useCallback(() => {
    const success = enterFullscreen();
    setInFullscreen(success);
  }, [enterFullscreen]);
  const handleFullscreenExit = useCallback(() => {
    setInFullscreen(false);
    exitFullscreen();
  }, [exitFullscreen]);


  const [inGame, setInGame] = React.useState(false);
  const handlePlay = useCallback(() => {
    setInGame(true);
  }, []);

  const handleClose = useCallback(() => {
    setInGame(false);
  }, []);
  // End Game

  // Scores
  const [flyers, setFlyers] = useState<Flyer[]>([]);
  //const [flyers, setFlyers] = useState<Flyer[]>(
  //  new Array(INIT_BUFFER_SIZE).fill(null).map(() => ({ id: 0, text: '', bonus: false, active: false }))
  //);
  const { level, addScore } = useAccountState();
  const { scoreUp, scoreBonus } = useProgressionState();
  const controls = useAnimation();
  const handleAddScore = useCallback(() => {
    addScore(scoreUp);
    controls.start(bounceAnimationRegular);

    // Effect for the score
    const newFlyer = createFlyer(`+${scoreUp}`);
    setFlyers(old => [...old, newFlyer]);
    setTimeout(() => setFlyers(flyers => flyers.filter(flyer => flyer.id !== newFlyer.id)), 1000);
    // Effect for the score
    /*
    setFlyers((old) => {
      const nextBufferIndex = old.findIndex(flyer => !flyer.active);
      if (nextBufferIndex === -1) {
        const updatedFlyers = [...old, newFlyer];
        return updatedFlyers;
      }

      const updatedFlyers = [...old];
      updatedFlyers[nextBufferIndex] = newFlyer;
      return updatedFlyers;
    });
    setTimeout(() => {
      setFlyers((flyers) => flyers.map(f => f.id === newFlyer.id ? { ...f, active: false } : f));
    }, 1000);
    */
    vibrate(20);
  }, [scoreUp, addScore, controls, vibrate]);

  const handleBonus = useCallback(() => {
    addScore(scoreBonus);
    controls.start(bounceAnimationBonus);

    // Effect for the score
    const newFlyer = createFlyer(`+${scoreBonus}`, true)
    setFlyers(old => [...old, newFlyer]);
    setTimeout(() => setFlyers(flyers => flyers.filter(flyer => flyer.id !== newFlyer.id)), 1000);
    /*
    setFlyers((old) => {
      const nextBufferIndex = old.findIndex(flyer => !flyer.active);
      if (nextBufferIndex === -1) {
        const updatedFlyers = [...old, newFlyer];
        return updatedFlyers;
      }

      const updatedFlyers = [...old];
      updatedFlyers[nextBufferIndex] = newFlyer;
      return updatedFlyers;
    });
    setTimeout(() => {
      setFlyers((flyers) => flyers.map(f => f.id === newFlyer.id ? { ...f, active: false } : f));
    }, 1000);
    */

    vibrate(500);
  }, [scoreBonus, addScore, controls, vibrate]);
  // End Scores

  // Reset the animation whenever the score changes
  /*
  useEffect(() => {
    controls.start(bounceAnimation.animate);
  }, [score, controls, bounceAnimation.animate]);
  */

  const [showfullscreen, setShowFullscreen] = React.useState(false);
  useEffect(() => {
    isTMA().then((isTMA) => {
      setShowFullscreen(!isTMA);
    }).catch((error) => {
      setShowFullscreen(true);
      console.error('GameViewer isTMA error', error);
    });
  }, []);
  
  // <PlaneView sx={{ width: '100%', height: '100%' }} />
  // console.log('GameViewer', flyers);
  return (
    <Root ref={fullScreenRef}>
      <BalloonAction
        sx={{ width: '100%', height: '100%' }}
        onSuccess={handleAddScore}
        onBonus={handleBonus}
      />
      <FirstInterationWidget sx={{ display: inGame ? 'none' : 'flex' }} onClick={handlePlay}>
        <Typography variant='h4' sx={{ ...bounceAnimationCss('0.15s') }} >
          Click me to start
        </Typography>
      </FirstInterationWidget>
      <Header>
        <XPProgress sx={{ width: '100%', paddingX: 2, ...bounceAnimationCss('0.15s') }} />
        <InfoContainerBottom>
          <LeftContainer>
            <AccountName variant='caption' sx={{ fontWeight: 'bold' }} />
          </LeftContainer>
          <CenterContainer>
            <motion.div initial={bounceAnimation.initial} animate={controls}>
              <ScoreTitle variant='h4' sx={{ ...bounceAnimationCss('0.25s') }} />
            </motion.div>
          </CenterContainer>
          <RightContainer>
            <AccountLevel variant='caption' />
          </RightContainer>
        </InfoContainerBottom>
      </Header>
      {
        !isIOS && showfullscreen && (
          <Footer sx={{ bottom: inFullscreen ? 16 : 72 }} >
            <GoldButton
              variant='contained'
              color='primary'
              size='large'
              sx={{ minWidth: 200, minHeight: 30 }}
              onClick={inFullscreen ? handleFullscreenExit : handleFullscreenEnter}
            >
              {inFullscreen ? 'Close' : 'Full Screen'}
            </GoldButton>
          </Footer>
        )
      }
      {flyers.map((flyer, index) => (
         <FlyerItem key={flyer.id} bonus={flyer.bonus}>
            {flyer.text}
          </FlyerItem>
        ))}
      {/*
      <FlyerWrapper>
        {flyers.map((flyer, index) => (
          <div key={index}>
            <HiddenInput
              type="checkbox"
              id={`flyer-${index}`}
              checked={flyer.active}
              readOnly
              style={{ display: 'none' }}
            />
            <FlyerItem
              bonus={flyer.bonus}
              htmlFor={`flyer-${index}`}
            >
              {flyer.text}
            </FlyerItem>
          </div>
        ))}
      </FlyerWrapper>
      */}
    </Root>
  );
};

export default GameViewer;