import cfg from '../config';
import { Upgrade } from '../types/upgrade';


export const fetchUpgrades = async () => {
  const response = await fetch(`${cfg.apiUrl}/api/v1/upgrades`);
  if (!response.ok) {
    throw new Error('Failed to fetch upgrades');
  }
  return response.json() as Promise<Upgrade[]>;
}