import { User } from "../types/user";
import cfg from "../config";
import * as auth from './auth';

type SignInResponse = {
  user: User;
  access_token: string;
}

export const signIn = async (initDataRaw: string): Promise<User> => {
  const response = await fetch(`${cfg.apiUrl}/api/v1/auth/telegram`, {
    method: 'POST',
    headers: {
      Authorization: `tma ${initDataRaw}`
    },
  });

  if (!response.ok) {
    throw new Error('Failed to sign in');
  }
  
  const data: SignInResponse = await response.json();
  auth.saveToken(data.access_token);
  return data.user;
}

type UnSafeSingInData = {
  name: string;
  token: string;
}

export const unsafeSignIn = async (data: UnSafeSingInData): Promise<User> => {
  const response = await fetch(`${cfg.apiUrl}/api/v1/auth/unsafe`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Failed to sign in');
  }
  
  const result = await response.json();
  auth.saveToken(result.access_token);
  return result.user;
}

export const signOut = () => {
  auth.cleanToken();
}