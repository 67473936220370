import React, { useEffect } from 'react'
//import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from './components/providers/ThemeProvider';
import './models/init';
import SWRProvider from './components/providers/SWRProvider';
import { AccountGuard, AccountProvider } from './components/providers/AccountProvider';
import { AccountBallonProvider } from './components/providers/AccountBallonProvider';
import { AccountStateProvider } from './components/providers/AccountStateProvider';
import { BalloonModelProvider } from './components/providers/BalloonModelProvider';
// import { SDKProvider } from '@tma.js/sdk-react';
import { isTMA, retrieveLaunchParams, postEvent, setDebug } from '@tma.js/sdk';
import StartPage from './pages';
import GamePage from './pages/game';
import StorePage from './pages/store';
import Friendspage from './pages/friends';
import AdditionalPage from './pages/additional';
import GalleryPage from './pages/gallery';
import SigInPage from './pages/signin';

function App() {
  useEffect(() => {
    //setDebug(true)
    isTMA().then((isTMA) => {
      if (isTMA) {
        const lp = retrieveLaunchParams();

        // Some versions of Telegram don't need the classes above.
        if (['macos', 'tdesktop', 'weba', 'web', 'webk'].includes(lp.platform)) {
          return;
        }

        // Expand the application.
        postEvent('web_app_expand');
      }
    }).catch((error) => {
      console.error('MyApp isTMA error', error);
    });
  }, []);

  return (
    <Router>
      <SWRProvider>
        <ThemeProvider>
          <AccountProvider>
            <AccountStateProvider>
              <AccountGuard>
                <AccountBallonProvider>
                  <BalloonModelProvider>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    {/* @ts-ignore */}
                    <Routes>
                      <Route path="/" element={<StartPage />} />
                      <Route path="/game" element={<GamePage />} />
                      <Route path="/store" element={<StorePage />} />
                      <Route path="/friends" element={<Friendspage />} />
                      <Route path="/additional" element={<AdditionalPage />} />
                      <Route path="/gallery" element={<GalleryPage />} />
                      <Route path="/signin" element={<SigInPage />} />
                    </Routes>
                  </BalloonModelProvider>
                </AccountBallonProvider>
              </AccountGuard>
            </AccountStateProvider>
          </AccountProvider>
        </ThemeProvider>
      </SWRProvider>
    </Router>
  );
}

export default App;
