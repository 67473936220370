import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";



export default function TapIcon({ htmlColor, ...props}: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.2585 17.6418C8.60679 17.1397 8.21924 16.5391 8.21924 15.844V12.1461C8.22337 12.1173 8.22994 12.0888 8.23887 12.0611C8.22868 12.0136 8.22212 11.9655 8.21924 11.9171C8.21924 9.48891 12.8268 8.21921 17.3821 8.21921C21.9373 8.21921 26.5449 9.48891 26.5449 11.9171C26.542 11.9655 26.5355 12.0136 26.5253 12.0611C26.5342 12.0888 26.5408 12.1173 26.5449 12.1461V15.844C26.542 16.0964 26.4886 16.3456 26.3878 16.577C26.391 16.579 26.3941 16.5809 26.3972 16.5828C24.8972 18.6265 21.9359 20 17.7094 20C14.2633 20 11.2698 19.0868 9.2585 17.6418ZM18.0366 15.6018C19.5819 15.574 21.119 15.3698 22.618 14.9932V17.5588C21.1125 17.9766 19.5595 18.1988 17.9973 18.2198C18.0204 18.1613 18.0336 18.0994 18.0366 18.0365V15.6018ZM16.7669 18.2198C15.2046 18.1988 13.6516 17.9766 12.1462 17.5588V14.9932C13.6451 15.3698 15.1823 15.574 16.7276 15.6018V18.0365C16.7305 18.0994 16.7438 18.1613 16.7669 18.2198ZM23.8942 14.5874C24.3645 14.4119 24.8144 14.1858 25.2359 13.9133V15.844C25.2359 16.2236 24.7712 16.6686 23.9269 17.0679V14.7641C23.9224 14.7042 23.9114 14.6449 23.8942 14.5874ZM10.8372 17.0679C9.9929 16.6686 9.52821 16.2236 9.52821 15.844V13.9133C9.94974 14.1858 10.3996 14.4119 10.8699 14.5874C10.8527 14.6449 10.8417 14.7042 10.8372 14.7641V17.0679Z" fill={htmlColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.42838 9.79016C1.42994 9.78613 1.4315 9.78209 1.43308 9.77807L1.47235 9.71916C0.562616 9.16285 -0.000244141 8.46255 -0.000244141 7.6248V3.92694C0.00388682 3.89808 0.0104556 3.86962 0.0193909 3.84186C0.00920068 3.79443 0.00263637 3.7463 -0.000244141 3.69787C-0.000244141 1.26972 4.60735 1.52588e-05 9.16259 1.52588e-05C13.7178 1.52588e-05 18.3254 1.26972 18.3254 3.69787C18.3225 3.7463 18.316 3.79443 18.3058 3.84186C18.3147 3.86962 18.3213 3.89808 18.3254 3.92694V7.6248C18.3225 7.87718 18.2691 8.12642 18.1683 8.35783C18.4419 8.52676 18.667 8.69843 18.8515 8.86931C16.7777 10.6307 13.3578 11.7809 9.49026 11.7809C6.34623 11.7809 3.49808 11.0208 1.42838 9.79016ZM9.81707 7.38264C11.3624 7.35484 12.8995 7.15063 14.3985 6.77397V9.33956C12.893 9.75745 11.34 9.97962 9.77781 10.0006C9.80087 9.94208 9.81414 9.88016 9.81707 9.81734V7.38264ZM8.54737 10.0006C6.98514 9.97962 5.43213 9.75745 3.92668 9.33956V6.77397C5.42567 7.15063 6.96277 7.35484 8.5081 7.38264V9.81734C8.51104 9.88016 8.5243 9.94208 8.54737 10.0006ZM15.6747 6.36818C16.145 6.19268 16.5949 5.96665 17.0164 5.69406V7.6248C17.0164 8.0044 16.5518 8.44946 15.7075 8.84869V6.5449C15.7029 6.48499 15.6919 6.42575 15.6747 6.36818ZM2.61771 8.84869C1.77342 8.44946 1.30873 8.0044 1.30873 7.6248V5.69406C1.73026 5.96665 2.18013 6.19268 2.65043 6.36818C2.63323 6.42575 2.62226 6.48499 2.61771 6.5449V8.84869Z" fill={htmlColor}/>
      </svg>
    </SvgIcon>
  );
}


