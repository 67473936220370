import React, { useEffect } from 'react';
import {
  ListItem,
  ListItemText,
  BoxProps,
  Typography,
  styled,
  Box,
  ListItemAvatar,
  Avatar,
} from '@mui/material';
import * as api from '../../api/users';
import { Leader } from '../../types/leader';
import HelpButton from '../dumps/HelpButton';
import { bounceAnimationCss } from '../../lib/animations';

const List = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(1),
}));

const Item = styled(ListItem)<{ duration?: string }>(({ theme, duration = '0.15s' }) => ({
  // display: 'flex',
  // width: '100%',
  //paddingTop: '10px',
  //paddingBottom: '10px',
  paddingLeft: '12px',
  paddingRight: '12px',
  background: theme.palette.background.paper,
  ...bounceAnimationCss(duration),
}));

const Img = styled('img')({
  width: '11px',
  height: '11px',
  marginLeft: '6px',
  marginRight: '4px',
  transform: 'translateY(1px)',
});

const Label = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: '400',
}));

const SubLabel = styled(Typography)(({ theme }) => ({
  fontSize: 10,
  fontWeight: '4500',
}));

const randomFriends = Array.from({ length: 40 }, (_, i) => ({
  id: i.toString(),
  name: `Friend ${i}`,
  score: Math.floor(Math.random() * 100),
}));

export type FriendListProps = BoxProps;

const FriendList: React.FC<FriendListProps> = ({ ...props }) => {
  const [friends, setFriends] = React.useState<Leader[]>([]);
  useEffect(() => {
    api.fetchFriends().then((items) => {
      setFriends(items);
    }).catch((error) => {
      //setLeaders(randomFriends);
      console.error(error);
    });
  }, []);
  return (
    <List {...props}>
      {friends.map((f, index) => (
        <Item key={f.id}
          secondaryAction={(
            <HelpButton />
          )}
          duration={`${index * 0.05}s`}
        >
          <ListItemAvatar>
            <Avatar src='/logo128.webp' />
          </ListItemAvatar>
          <ListItemText
            primary={(
              <Label>
                {f.name}
              </Label>
            )}
            secondary={(
              <SubLabel>
                lvl {f.level || 1} <Img src='/coin.webp' alt='coin' /> {f.score}
              </SubLabel>
            )}
          />
        </Item>
      ))}
    </List>
  );
};

export default FriendList;