import { SvgIcon, SvgIconProps } from "@mui/material";

export default function FriendsIcon({ htmlColor, ...props}: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="96" height="50" viewBox="0 0 96 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 21.9999C20.4183 21.9999 24 18.4182 24 13.9999C24 9.58166 20.4183 5.99994 16 5.99994C11.5817 5.99994 8 9.58166 8 13.9999C8 18.4182 11.5817 21.9999 16 21.9999Z" fill={htmlColor}/>
        <path d="M26.68 27.6C24.604 29.1628 22.9198 31.187 21.7605 33.5125C20.6011 35.8381 19.9984 38.4014 20 41V42H3C1.36 42 0 40.64 0 39V37C0 30.92 4.92 26 11 26H21C23.08 26 25.04 26.6 26.68 27.6Z" fill={htmlColor}/>
        <path d="M80 21.9999C84.4183 21.9999 88 18.4182 88 13.9999C88 9.58166 84.4183 5.99994 80 5.99994C75.5817 5.99994 72 9.58166 72 13.9999C72 18.4182 75.5817 21.9999 80 21.9999Z" fill={htmlColor}/>
        <path d="M96.0001 37V39C96.0001 40.64 94.6401 42 93.0001 42H76.0001V41C76.0001 35.52 73.3601 30.64 69.3201 27.6C70.9601 26.6 72.9201 26 75.0001 26H85.0001C91.0801 26 96.0001 30.92 96.0001 37Z" fill={htmlColor}/>
        <path d="M48 24C54.6274 24 60 18.6274 60 12C60 5.37258 54.6274 0 48 0C41.3726 0 36 5.37258 36 12C36 18.6274 41.3726 24 48 24Z" fill={htmlColor}/>
        <path d="M59 30.0001H37C34.0833 30.0022 31.2866 31.1618 29.2242 33.2242C27.1617 35.2867 26.0021 38.0833 26 41.0001V47.0001C26 48.6561 27.344 50.0001 29 50.0001H67C67.7956 50.0001 68.5587 49.684 69.1213 49.1214C69.6839 48.5588 70 47.7957 70 47.0001V41.0001C69.9979 38.0833 68.8383 35.2867 66.7758 33.2242C64.7134 31.1618 61.9167 30.0022 59 30.0001Z" fill={htmlColor}/>
      </svg>
    </SvgIcon>
  );
}