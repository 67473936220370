import { vec3 } from "gl-matrix";
import { Mesh, PrimitiveData, offsetPrimitive } from "./Mesh";
import { toMesh as _toMesh } from "./Mesh";

export class Primitive {
  data: PrimitiveData;

  constructor(
    public inData: PrimitiveData
  ) {
    this.data = inData;
  }

  public toData(): PrimitiveData {
    return this.data;
  }

  public toOffset(offset: vec3): Primitive {
    const newData =  offsetPrimitive(this.data, offset);
    return new Primitive(newData);
  }

  public toMesh(): Mesh {
    return _toMesh(this.data);
  }
}