import React from 'react';
import { Avatar, Typography, TypographyProps } from '@mui/material';
import { useAccountState } from '../../providers/AccountStateProvider';

// <Avatar src='/coin.webp' sx={{ width: 32, height: 32 }} /> and  {score} in line

export type AccountScoreProps = TypographyProps;
function AccountScore({ sx, ...props }: AccountScoreProps) {
  const { score } = useAccountState();
  return (
    <Typography {...props} sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      fontSize: 35,
      fontWeight: 700,
      ...sx
    }}>
      <Avatar src='/coin.webp' sx={{ width: 36, height: 36 }} /> {score}
    </Typography>
  )
}

export default AccountScore;