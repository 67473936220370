export const bounceAnimationCss = (duration = '0.15s'): React.CSSProperties => ({
  // @ts-ignore
  '@keyframes fadeInScale': {
    '0%': {
      opacity: 0,
      transform: 'scale(0.8)',
    },
    '50%': {
      opacity: 1,
      transform: 'scale(1.1)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  animation: `fadeInScale ${duration} ease-in-out forwards`,
});